import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { Form, Input, Button, ConfigProvider } from "antd";
import { MailOutlined } from "@ant-design/icons";
import "./signup.css";
import { signupUser, verifyNewUser } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";

const SignupPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
    const verifyUser = () => {
      verifyNewUser(token)
        .then((response) => {
          if (response.data.existingUser) {
            navigate("/");
            return;
          }
          setData(response.data);
        })
        .catch((error) => {
          console.error("Verification failed:", error);
        });
    };
    verifyUser();
  }, [token, navigate]);

  const handleSignup = (values) => {
    const { firstName, lastName, password } = values;
    const userData = {
      email: data?.email,
      firstName,
      lastName,
      password,
    };

    signupUser(userData, token)
      .then((res) => {
        if (res.status === 200) {
          NotificationInstance.success({
            message: "Signup Successful, Please Login",
          });
          navigate("/login");
        } else {
          NotificationInstance.error({
            message: "Signup failed, please try again.",
          });
        }
      })
      .catch((error) => {
        NotificationInstance.error({
          message: "An error occurred while signing up.",
        });
        navigate("/");
        return;
      });
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <div className="leaproad-logo">
          <Logo />
        </div>
        <p className="signup-content">Sign up with your personal details</p>

        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Button: {
                colorPrimary: "#3045ff",
                colorPrimaryHover: "#3045ff",
                colorPrimaryActive: "#3045ff",
                primaryShadow: "none",
                controlHeight: 45,
              },
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: "#3045ff",
                activeBorderColor: "#3045ff",
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
            },
          }}
        >
          <Form
            name="signup-form"
            onFinish={handleSignup}
            layout="vertical"
            className="signup-form-container"
          >
            <Form.Item name="email" label="Email">
              <Input
                prefix={<MailOutlined />}
                value={data?.email}
                readOnly
                placeholder={data?.email || "email"}
              />
            </Form.Item>
            <div className="name-inputs">
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter your first name" },
                ]}
              >
                <div className="nameInput">
                  <Input placeholder="First Name" />
                </div>
              </Form.Item>

              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter your last name" },
                ]}
              >
                <div className="nameInput">
                  <Input placeholder="Last Name" />
                </div>
              </Form.Item>
            </div>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please enter your password" },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
                  message:
                    "Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long.",
                },
              ]}
              style={{ marginBottom: "25px" }}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please confirm your password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                block
              >
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default SignupPage;
