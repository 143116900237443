import {
  Avatar,
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";

const CourseOutline = ({
  status,
  isModalOpen,
  handleOk,
  handleCancel,
  courseData,
  onChange,
  selectedStep,
  courseStepResults,
  courseSteps,
  generatingLesson,
  courseBriefing,
  setCourseSteps,
  setCourse,
  handleRegenerateStep
}) => {
  const [updated, setUpdated] = useState(false);
  const [form] = Form.useForm();
  const renderOutlineButton = () => {
    const { OUTLINE, LESSON, LESSON_BRIEFINGS } = courseSteps || {};

    switch (selectedStep) {
      case "OUTLINE":
        if (LESSON) return null;
        return (
          <Button
            onClick={() => handleOk("LESSON")}
            loading={generatingLesson !== null}
            type="primary"
          >
            Confirm & Generate Next Step
          </Button>
        );

      case "LESSON":
        if (LESSON_BRIEFINGS) return null;
        return (
          <Button
            type="primary"
            onClick={() => handleOk("LESSON_BRIEFINGS")}
            loading={generatingLesson !== null}
          >
            Confirm & Generate Next Step
          </Button>
        );

      case "LESSON_BRIEFINGS":
        if (courseBriefing) return null;
        return (
          <Popconfirm
            overlayStyle={{ width: 400 }}
            title="Course Outline"
            description="After submitting the course Outline, you won't be able to change the details. Do you want to proceed?"
            onConfirm={() => handleOk()}
          >
            <Button type="primary" loading={generatingLesson !== null}>
              Confirm & Generate Lessons
            </Button>
          </Popconfirm>
        );

      default:
        return null;
    }
  };
  const onModalClose = () => {
    handleCancel();
    onChange(courseStepResults[selectedStep], selectedStep);
  };

  const handleRegenerate = () => {
    const clearedSteps = {
      OUTLINE: selectedStep === "OUTLINE" ? "" : courseSteps.OUTLINE,
      LESSON:
        selectedStep === "LESSON" || selectedStep === "OUTLINE"
          ? ""
          : courseSteps.LESSON,
      LESSON_BRIEFINGS:
        selectedStep === "LESSON_BRIEFINGS" ||
        selectedStep === "LESSON" ||
        selectedStep === "OUTLINE"
          ? ""
          : courseSteps.LESSON_BRIEFINGS,
    };
    setCourse((prev) => ({ ...prev, courseStepResults: clearedSteps }));
    setCourseSteps(clearedSteps);
    handleRegenerateStep(selectedStep, clearedSteps);
  };
  const handleUpdate = () => {
    const clearedSteps = {
      OUTLINE: courseSteps.OUTLINE,
      LESSON: selectedStep === "OUTLINE" ? "" : courseSteps.LESSON,
      LESSON_BRIEFINGS:
        selectedStep === "LESSON" || selectedStep === "OUTLINE"
          ? ""
          : courseSteps.LESSON_BRIEFINGS,
    };
    setCourse((prev) => ({ ...prev, courseStepResults: clearedSteps }));
    setCourseSteps(clearedSteps);
    handleOk(selectedStep, clearedSteps);
  };

  const handleValidate = (type) => {
    form
      .validateFields()
      .then((values) => {
        if (type === "update") {
          handleUpdate();
        } else {
          handleRegenerate();
        }
      })
      .catch((err) => {});
  };

  const renderUpdateButton = () => {
    const { OUTLINE, LESSON, LESSON_BRIEFINGS } = courseStepResults || {};
    const hasNext =
      selectedStep === "OUTLINE"
        ? LESSON
        : selectedStep === "LESSON"
        ? LESSON_BRIEFINGS
        : false;
    if (hasNext && updated) {
      return (
        <Popconfirm
          title="Are you sure?"
          description="This will update the current step and clear all steps after it."
          onConfirm={() => handleValidate("update")}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">Update</Button>
        </Popconfirm>
      );
    }
  };

  useEffect(() => {
    if (courseData) {
      form.setFieldValue(selectedStep, courseData);
    }
  }, []);

  return (
    <Modal
      centered
      width={700}
      className="project-document"
      title={"Course Outline"}
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <Flex justify="flex-end" gap={20}>
          {(!courseBriefing || generatingLesson) && (
            <Popconfirm
              title="Are you sure?"
              description="This will clear the current step and all steps after it."
              onConfirm={() => handleValidate("regenerate")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Regenerate</Button>
            </Popconfirm>
          )}
          {generatingLesson === null && renderUpdateButton()}
          {renderOutlineButton()}
          <Button className="cancel-button" onClick={onModalClose}>
            Close
          </Button>
        </Flex>
      )}
    >
      <Form form={form}>
        <Form.Item
          required={false}
          name={selectedStep}
          className="title"
          rules={[
            {
              required: true,
              message: "Please enter the answer",
            },
          ]}
        >
          <Input.TextArea
            spellCheck={false}
            onChange={(e) => {
              onChange(e.target.value, selectedStep);
              setUpdated(true);
            }}
            readOnly={
              status === "COURSE_BRIEFING_CONFIRMED" ||
              generatingLesson ||
              courseBriefing
            }
            value={courseData}
            autoSize={{ minRows: 20, maxRows: 20 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CourseOutline;
