import React, { useEffect, useState } from "react";
import "./users.css";
import {
  Avatar,
  Badge,
  Button,
  ConfigProvider,
  Input,
  Select,
  Tooltip,
} from "antd";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Logo } from "../../assets/images/searchPrefix.svg";
import { ReactComponent as Check } from "../../assets/images/checkMark.svg";
import { ReactComponent as Delete } from "../../assets/images/trash.svg";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  deleteSelectedJourneys,
  deleteSelectedUser,
  getOrganizationStaffs,
  getUsers,
} from "../../api/adminService";
import { timeCreatedOn } from "../../utils/format";
import { RoleTag, StatusTag } from "../../components/tags/tags";
import { PRIMARY_COLOR } from "../../utils/colors";
import TableActions from "../../components/actions/tableActions";
import NotificationInstance from "../../services/notificationServices";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import UserFilterDrawer from "../../components/modals/userFilterDrawer";
import AddUserModal from "../../components/modals/addUserModal";
import CustomTable from "../../components/customTable/customTable";

const TitleDiv = ({ name, id, category, image }) => {
  return (
    <div className="journey-title-wrapper">
      <Avatar
        shape={"circle"}
        src={
          image ??
          `https://ui-avatars.com/api/?name=${name}&background=9EA5AD&color=fff`
        }
        size={40}
        // icon={<Logo />}
      />
      <span className="title-name">{name}</span>
    </div>
  );
};

const sortOptions = [
  {
    value: "firstName.ASC",
    label: "Name (A to Z)",
  },
  {
    value: "firstName.DESC",
    label: "Name (Z to A)",
  },
  {
    value: "email.ASC",
    label: "Email (A to Z)",
  },
  {
    value: "email.DESC",
    label: "Email (Z to A)",
  },
  {
    value: "createdAt.ASC",
    label: "Created On (oldest first)",
  },
  {
    value: "createdAt.DESC",
    label: "Created On (newest first)",
  },
];

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "25%",
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "25%",
  },
  // {
  //   title: "Created on",
  //   dataIndex: "createdOn",
  //   width: "17%",
  // },
  // {
  //   title: "Role",
  //   dataIndex: "role",
  //   width: "17%",
  // },
  {
    title: "Org. Role",
    dataIndex: "orgRole",
    width: "22%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "22%",
  },
  {
    title: "",
    dataIndex: "action",
    width: "5%",
  },
];

const Users = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [sortField, setSortField] = useState();
  const [filter, setfilter] = useState({
    role: "",
    orgRole: "",
    status: "",
    category: [],
    active: false,
  });
  const [loading, setLoading] = useState(false);
  const [sortOrder, setsortOrder] = useState("");
  const [organizationId, setOrganizationId] = useState();
  const [pageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const [, , setDisplayName, , userDetails] = useOutletContext();

  const appRole = {
    super_admin: "SUPER_ADMIN",
    organization_admin: "ORGANIZATION_ADMIN",
    organization_creator: "ORGANIZATION_CREATOR",
  };

  useEffect(() => {
    setDisplayName(["Users"]);
  }, []);

  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };
  const onSort = (val) => {
    setCurrentPage(1);
    const value = val?.split(".");
    setSortField(value[0]);
    setsortOrder(value[1]);
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const handleOk = () => {
    setRefresh(!refresh);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleDeleteUser = (user) => {
    setIsUserDeleteModalOpen(false);
    deleteSelectedUser({
      organizationId: organizationId,
      staffId: user._id,
    })
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted User",
        });
        setCurrentPage(1);
        setRefresh(!refresh);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message || "Please verify & try again.",
        });
      });
  };

  const formatUserData = (data) =>
    data?.map((item) => ({
      key: item?._id,
      name: (
        <TitleDiv
          name={`${item?.firstName ?? ""} ${item?.lastName ?? ""}`.trim()}
          id={item?._id}
          category={item?.categoryInfo?._id}
          image={item?.image}
        />
      ),
      email: item.email,
      createdOn: item.createdAt ? timeCreatedOn(item.createdAt) : `Unknown`,
      image: item.image,
      // role: <RoleTag type={item.appRole} />,
      role: item.appRole,
      status: <StatusTag status={item.status} />,
      orgRole: <RoleTag type={item.organizationRole} />,
      action: (
        <TableActions
          handleDelete={() => {
            setSelectedUser(item);
            setIsUserDeleteModalOpen(true);
          }}
          type="user"
        />
      ),
    }));

  const fetchUserData = async () => {
    try {
      setLoading(true);
      let res;

      if (
        userDetails?.organizationInfo[0]?.organizationRole ||
        userDetails?.appRole === appRole.organization_admin
      ) {
        res = await getOrganizationStaffs(
          searchKey,
          filter.status,
          userDetails.organizationInfo[0]._id,
          filter.role,
          filter.orgRole,
          pageSize,
          currentPage,
          sortField,
          sortOrder
        );
      }

      const formattedData = formatUserData(res?.data || []);
      setData(formattedData);
      setTotal(res?.info?.totalCount ?? 0);
      setLoading(false);
      setOrganizationId(res?.info?.organizationId);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [
    searchKey,
    sortField,
    sortOrder,
    filter,
    pageSize,
    currentPage,
    refresh,
    navigate,
    userDetails,
  ]);

  return (
    <div>
      <div className="journey-header">
        <div className="journey-title">
          <h1>All Users</h1>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
            },
            components: {
              Select: {
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Radio: {
                colorPrimary: PRIMARY_COLOR,
              },
              Checkbox: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
              },
              Drawer: {
                footerPaddingBlock: 30,
              },
              Badge: {
                dotSize: 10,
              },
              Menu: {
                colorPrimary: PRIMARY_COLOR,
              },
            },
          }}
        >
          <div className="journey-action">
            <div className="sort-action">
              <span className="sort-prefix">Sort : </span>
              <Select
                placeholder="Select a sort"
                optionFilterProp="children"
                onSelect={onSort}
                variant="borderless"
                popupMatchSelectWidth={true}
                defaultValue={"createdAt.DESC"}
                popupClassName="sort-options"
                title="label"
                options={sortOptions}
                menuItemSelectedIcon={<Check />}
                listHeight={270}
              />
            </div>

            <Input
              width={200}
              placeholder="Search"
              prefix={<Search />}
              suffix={<Logo />}
              className="search-input"
              onChange={onSearch}
            />
            <Badge dot={filter.active}>
              <Button
                onClick={() => setIsDrawerOpen(true)}
                className="filter-button"
                color="white"
              >
                Filter
              </Button>
            </Badge>
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="create-button"
            >
              Add New User
            </Button>
            <UserFilterDrawer
              isDrawerOpen={isDrawerOpen}
              handleCancel={handleCancelDrawer}
              setfilter={setfilter}
              roleFilter={filter.role}
              orgRoleFilter={filter.orgRole}
              statusFilter={filter.status}
              // categoryFilter={filter.category}
              active={filter.active}
              setJourneyPage={setCurrentPage}
            />
            <AddUserModal
              isModalOpen={isModalOpen}
              handleCancel={handleCancel}
              handleOk={handleOk}
              setIsModalOpen={setIsModalOpen}
              organizationId={userDetails?.organizationInfo[0]?._id}
            />
          </div>
          <DeleteConfirmModal
            isModalOpen={isUserDeleteModalOpen}
            handleCancel={() => setIsUserDeleteModalOpen(false)}
            handleOk={handleDeleteUser}
            type={"User"}
            selectedJourney={selectedUser}
          />
        </ConfigProvider>
      </div>
      <CustomTable
        data={data}
        loading={loading}
        columns={columns}
        total={total}
        pageSize={pageSize}
        item={"Users"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Users;
