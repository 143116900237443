import React, { useEffect, useState } from "react";
import "./categories.css";
import { Button, ConfigProvider, Input, Select } from "antd";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Logo } from "../../assets/images/searchPrefix.svg";
import { ReactComponent as Check } from "../../assets/images/checkMark.svg";
import { useOutletContext } from "react-router-dom";
import {
  addNewCategoryItem,
  deleteCategory,
  getCategories,
  updateCategory,
} from "../../api/adminService";
import { timeCreatedOn } from "../../utils/format";
import { StatusTag } from "../../components/tags/tags";
import { PRIMARY_COLOR } from "../../utils/colors";
import CategoriesTableActions from "../../components/actions/categoriesAction/categoriesTableActions";
import CustomPagination from "../../components/customPagination/customPagination";
import CategoriesTable from "../../components/categoriesList/categoriesList";
import NotificationInstance from "../../services/notificationServices";
import DeleteModal from "../../components/modals/deleteConfirmModal";
import PublishModal from "../../components/modals/publishModal";
import AddCategoryModal from "../../components/modals/addCategoryModal";
import UnpublishModal from "../../components/modals/unPublishModal";

const sortOptions = [
  {
    value: "name.ASC",
    label: "Name (A to Z)",
  },
  {
    value: "name.DESC",
    label: "Name (Z to A)",
  },
  {
    value: "status.ASC",
    label: "Status (Draft to Published)",
  },
  {
    value: "status.DESC",
    label: "Status (Published to Draft)",
  },
  {
    value: "createdAt.ASC",
    label: "CreatedAt (old to new)",
  },
  {
    value: "createdAt.DESC",
    label: "CreatedAt (new to old)",
  },
];
const Categories = () => {
  const [categoryName, setCategoryName] = useState({
    value: "",
    error: false,
  });
  const [, , , , , setRecheck] = useOutletContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [progress, setInprogress] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [status, setStatus] = useState("");
  const [sortField, setSortField] = useState();
  const [loading, setLoading] = useState(false);
  const [sortOrder, setsortOrder] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [update, setUpdate] = useState(0);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [categoryStatus, setCategoryStatus] = useState("");
  const [categoryLabel, setCategoryLabel] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false);
  const [statusModal, setStatusModal] = useState("");
  const [, , setDisplayName] = useOutletContext();
  const CATEGORY_STATUSES = {
    DRAFT: "DRAFT",
    PUBLISHED: "PUBLISHED",
  };

  useEffect(() => {
    setDisplayName(["Categories"]);
  }, []);

  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };
  const onSort = (val) => {
    setCurrentPage(1);
    const [field, order] = val.split(".");
    setSortField(field);
    setsortOrder(order);
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addNewCategory = (name, type) => {
    addNewCategoryItem({ name: name, status: type })
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully added new category.",
        });
        const newData = {
          key: res?.data?._id,
          name: res?.data?.name,
          status: <StatusTag status={res?.data?.status} />,
          createdBy: res?.data?.createdBy,
          createdAt: timeCreatedOn(res?.data?.createdAt),
          action: (
            <CategoriesTableActions
              handleEdit={() => {
                setCategoryLabel(res?.data?.name);
                setCategoryStatus(res?.data?.status);
                setEditCategoryId(res?.data?._id);
                setEditModalOpen(true);
              }}
              handleDelete={() => {
                setCategoryIdToDelete(res?.data?._id);
                setDeleteModalOpen(true);
              }}
              handleStatus={() => {
                setCategoryStatus(res?.data?.status);
                setEditCategoryId(res?.data?._id);
                setCategoryName(res?.data?.name);
                if (res?.data?.status === CATEGORY_STATUSES.DRAFT) {
                  setStatusModal("DRAFT");
                  setIsPublishModalOpen(true);
                } else if (res?.data?.status === CATEGORY_STATUSES.PUBLISHED) {
                  setStatusModal("PUBLISHED");
                  setIsUnpublishModalOpen(true);
                }
              }}
              categoryStatus={res?.data?.status}
            />
          ),
        };
        setCategory([newData, ...category]);
        setCategoryName({ value: "", error: false });
        setInprogress(false);
        handleCancel();
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };

  const handleDeleteCategory = (categoryId) => {
    deleteCategory(categoryId)
      .then((res) => {
        setDeleteModalOpen(false);
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted category",
        });
        setCategory((prevCategoryItems) => {
          return prevCategoryItems.filter((item) => item.key !== categoryId);
        });
      })
      .catch((error) => {
        console.error("Delete category error:", error);
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };

  const confirmDeleteCategory = () => {
    if (categoryIdToDelete) {
      handleDeleteCategory(categoryIdToDelete);
      setCategoryIdToDelete(null);
    }
  };

  const editCategory = () => {
    if (!editCategoryId) return;
    const request = {
      name: categoryName.value,
      status: CATEGORY_STATUSES.DRAFT,
    };
    if (request.name.trim() === "") {
      setInprogress(false);
      return;
    }
    setCategoryStatus(CATEGORY_STATUSES.DRAFT);
    updateCategory(editCategoryId, request)
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully updated category",
        });
        setCategory((prevCategoryItems) => {
          const updatedCategoryItems = prevCategoryItems.map((item) =>
            item.key === editCategoryId
              ? {
                  ...item,
                  name: categoryName.value,
                  status: CATEGORY_STATUSES.DRAFT,
                }
              : item
          );
          return updatedCategoryItems;
        });
        setEditModalOpen(false);
        setEditCategoryId(null);
        setInprogress(false);
        setUpdate((prevUpdate) => prevUpdate + 1);
        setRecheck((check) => !check);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message || "Please verify & try again.",
        });
        setInprogress(false);
      });
  };

  const confirmStatusUpdate = (newStatus) => {
    if (newStatus === CATEGORY_STATUSES.PUBLISHED) {
      handleUpdate(editCategoryId, newStatus, categoryName);
      setIsUnpublishModalOpen(false);
    } else {
      handleUpdate(editCategoryId, newStatus, categoryName);
      setIsPublishModalOpen(false);
    }
  };

  const handleUpdate = (categoryId, status, name) => {
    const request = {
      name: name,
      status: status,
    };
    updateCategory(categoryId, request)
      .then((res) => {
        setIsUnpublishModalOpen(false);
        setIsPublishModalOpen(false);
        setCategoryStatus(request.status);
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully updated category",
        });
        setCategory((prevCategoryItems) => {
          const updatedCategoryItems = [...prevCategoryItems];
          const index = updatedCategoryItems.findIndex(
            (item) => item.key === categoryId
          );
          if (index !== -1) {
            updatedCategoryItems[index] = {
              ...updatedCategoryItems[index],
              name: name,
              status: <StatusTag status={status} />,
              action: (
                <CategoriesTableActions
                  handleEdit={() => {
                    setCategoryLabel(name);
                    setCategoryStatus(status);
                    setEditCategoryId(categoryId);
                    setEditModalOpen(true);
                  }}
                  handleDelete={() => {
                    setCategoryIdToDelete(categoryId);
                    setDeleteModalOpen(true);
                  }}
                  handleStatus={() => {
                    setCategoryStatus(status);
                    setEditCategoryId(categoryId);
                    setCategoryName(name);
                    if (status === CATEGORY_STATUSES.DRAFT) {
                      setStatusModal("DRAFT");
                      setIsPublishModalOpen(true);
                    } else if (status === CATEGORY_STATUSES.PUBLISHED) {
                      setStatusModal("PUBLISHED");
                      setIsUnpublishModalOpen(true);
                    }
                  }}
                  categoryStatus={status}
                />
              ),
            };
          }
          return updatedCategoryItems;
        });
      })
      .catch((error) => {
        setIsUnpublishModalOpen(false);
        setIsPublishModalOpen(false);
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    getCategories(
      searchKey,
      status,
      pageSize,
      currentPage,
      sortField,
      sortOrder
    )
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          key: item._id,
          name: item.name,
          status: <StatusTag status={item.status} />,
          createdBy: item.createdBy,
          createdAt: timeCreatedOn(item.createdAt),
          action: (
            <CategoriesTableActions
              handleEdit={() => {
                setCategoryLabel(item.name);
                setCategoryStatus(item.status);
                setEditCategoryId(item._id);
                setEditModalOpen(true);
                setCategoryName({ ...categoryName, value: item.name });
              }}
              handleDelete={() => {
                setCategoryIdToDelete(item?._id);
                setDeleteModalOpen(true);
              }}
              handleStatus={() => {
                setCategoryStatus(item.status);
                setEditCategoryId(item._id);
                setCategoryName(item.name);
                if (item.status === CATEGORY_STATUSES.DRAFT) {
                  console.log("useeffect");
                  setStatusModal("DRAFT");
                  setIsPublishModalOpen(true);
                } else if (item.status === CATEGORY_STATUSES.PUBLISHED) {
                  setStatusModal("PUBLISHED");
                  setIsUnpublishModalOpen(true);
                }
              }}
              categoryStatus={item.status}
            />
          ),
        }));
        setCategory(responseData);
        setTotal(res?.info?.totalCount ?? 0);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [
    searchKey,
    status,
    sortField,
    sortOrder,
    pageSize,
    currentPage,
    refresh,
    update,
  ]);

  return (
    <div>
      <div className="journey-header">
        <div className="journey-title">
          <h1>All Categories</h1>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
            },
            components: {
              Select: {
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Radio: {
                colorPrimary: PRIMARY_COLOR,
              },
              Checkbox: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
              },
              Drawer: {
                footerPaddingBlock: 30,
              },
              Badge: {
                dotSize: 10,
              },
              Menu: {
                colorPrimary: PRIMARY_COLOR,
              },
            },
          }}
        >
          <div className="journey-action">
            <div className="sort-action">
              <span className="sort-prefix">Sort : </span>
              <Select
                placeholder="Select a sort"
                optionFilterProp="children"
                onSelect={onSort}
                variant="borderless"
                popupMatchSelectWidth={true}
                defaultValue={"createdAt.DESC"}
                popupClassName="sort-options"
                title="label"
                options={sortOptions}
                menuItemSelectedIcon={<Check />}
                listHeight={270}
              />
            </div>
            <Input
              width={200}
              placeholder="Search"
              prefix={<Search />}
              suffix={<Logo />}
              className="search-input"
              onChange={onSearch}
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="create-button"
            >
              Add New Category
            </Button>
            <AddCategoryModal
              isModalOpen={isModalOpen}
              handleCancel={handleCancel}
              handleOk={addNewCategory}
              category={categoryName}
              setCategory={setCategoryName}
              setInprogress={setInprogress}
              progress={progress}
            />
          </div>
        </ConfigProvider>
      </div>
      <div className="journey-wrapper">
        <CategoriesTable
          data={category}
          loading={loading}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        {total > pageSize && (
          <CustomPagination
            pageSize={pageSize}
            currentPage={currentPage}
            total={total}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
      <DeleteModal
        isModalOpen={deleteModalOpen}
        handleCancel={() => {
          setDeleteModalOpen(false);
          setCategoryIdToDelete(null);
        }}
        handleOk={confirmDeleteCategory}
        type={"Category"}
      />
      <AddCategoryModal
        isModalOpen={editModalOpen}
        handleCancel={() => setEditModalOpen(false)}
        handleOk={editCategory}
        category={categoryName}
        setCategory={setCategoryName}
        update={true}
        label={categoryLabel}
        defaultStatus={categoryStatus}
        setInprogress={setInprogress}
        progress={progress}
      />
      {isPublishModalOpen && statusModal === CATEGORY_STATUSES.DRAFT ? (
        <PublishModal
          isModalOpen={isPublishModalOpen}
          handleCancel={() => setIsPublishModalOpen(false)}
          handleOk={() => {
            confirmStatusUpdate("PUBLISHED");
          }}
          type="Category"
        />
      ) : isUnpublishModalOpen &&
        statusModal === CATEGORY_STATUSES.PUBLISHED ? (
        <UnpublishModal
          isModalOpen={isUnpublishModalOpen}
          handleCancel={() => setIsUnpublishModalOpen(false)}
          handleOk={() => {
            confirmStatusUpdate("DRAFT");
          }}
          type="Category"
        />
      ) : null}
    </div>
  );
};

export default Categories;
