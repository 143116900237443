import { Tag, Tooltip } from "antd";
import React from "react";
import "./tags.css";

const types = {
  Pre_Defined: "Pre-Defined",
  Fixed: "Fixed",
};
function formatString(input) {
  if (input) {
    const result = input.replace(/([A-Z][A-Z_]+)/g, function (match) {
      const formatted = match.toLowerCase().replace(/_/g, " ");
      return formatted.replace(/\b\w/g, (char) => char.toUpperCase());
    });
    if (result.toUpperCase() === result) {
      return result.charAt(0) + result.slice(1).toLowerCase();
    }

    return result.trim();
  } else {
    return "";
  }
}
export const CategoryTag = ({ category }) => {
  return <Tag className="category-tag">{category}</Tag>;
};
export const StatusTag = ({ status }) => {
  return (
    <Tooltip title={status}>
      <div style={{ width: "fit-content" }}>
        <Tag
          className={`status-tag ${
            status === "DRAFT" || status === "INACTIVE" ? "draft" : "published"
          }`}
        >
          {status}
        </Tag>
      </div>
    </Tooltip>
  );
};
export const TypeTag = ({ type }) => {
  return (
    <Tooltip title={types[type]}>
      <div style={{ width: "fit-content" }}>
        <Tag
          className={`type-tag ${type === "Fixed" ? "fixed" : "pre-defined"}`}
        >
          {types[type]}
        </Tag>
      </div>
    </Tooltip>
  );
};
export const CurrentStatusTag = ({ status }) => {
  return (
    <Tooltip title={status}>
      <div style={{ width: "fit-content" }}>
        <Tag
          className={`status-tags ${
            status === "DRAFT" ? "draft" : "published"
          }`}
        >
          {status}
        </Tag>
      </div>
    </Tooltip>
  );
};
export const RoleTag = ({ type }) => {
  return (
    <Tooltip title={formatString(type)}>
      <div style={{ width: "fit-content" }}>
        <Tag className={`type-tag `}>{formatString(type)}</Tag>
      </div>
    </Tooltip>
  );
};
