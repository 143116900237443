import React, { useEffect, useRef } from "react";
import { scriptUpdateStages } from "../../../utils/format";
import { Button, Col, Divider, Flex, Form, Input, Popconfirm, Row } from "antd";
import LessonDocument from "./lessonDocument";
import TextArea from "antd/es/input/TextArea";
import LessonScript from "../lessonScript/lessonScript";
import "./lessonDetails.css";

const LessonBrief = ({
  setUpdated,
  lessonScriptForm,
  sectionStep,
  questions,
  currentStatus,
  lesson,
  setSelectedScript,
  courseId,
  updateLesson,
  setFinal,
  regenerating,
  section,
  step,
  setContentStep,
  updated,
  onSubmit,
  selectedScript,
  setLesson,
  onUpdate,
  setSection,
  generateScript,
}) => {
  let nextStepShown = false;
  const container = useRef();
  const stepContainer = useRef();
  const handleDiscardForm = () => {
    setUpdated();
    lessonScriptForm.setFieldsValue({ ...lesson });
  };
  const renderButton = ({ type = "lessonForm" }) => {
    if (
      lesson?.state !== "created" &&
      lesson?.state !== "SCRIPT_GENERATION_IN_PROGRESS" &&
      !updated &&
      lesson?.finalScript
    ) {
      return (
        <Button
          type="primary"
          onClick={() => setContentStep((prev) => prev + 1)}
          className="brief-button"
        >
          Next
        </Button>
      );
    } else if (lesson?.state === "created" || updated) {
      const recreate = updated && updated?.includes("userContent");
      return lesson?.state !== "created" && recreate ? (
        <Flex gap={20}>
          <Button onClick={() => handleDiscardForm()} className="brief-button">
            Discard Changes
          </Button>
          <Popconfirm
            overlayStyle={{ width: 400 }}
            title="Lesson Briefing"
            description="Updating the lesson content will remove all the steps created from this lesson. If you proceed, all dependent steps will be deleted. Do you want to continue?"
            onConfirm={() => onSubmit(type, step.type, recreate)}
          >
            <Button
              type="primary"
              loading={sectionStep.status === "GENERATING"}
              className="brief-button"
            >
              {sectionStep.status === "GENERATING"
                ? "Updating Lesson"
                : "Update Lesson"}
            </Button>
          </Popconfirm>
        </Flex>
      ) : (
        <Flex gap={20}>
          {updated && currentStatus !== "created" && (
            <Button
              onClick={() => handleDiscardForm()}
              className="brief-button"
            >
              Discard Changes
            </Button>
          )}
          <Button
            type="primary"
            loading={sectionStep.status === "GENERATING"}
            onClick={() => onSubmit(type, step.type)}
            className="brief-button"
          >
            {lesson?.state === "created"
              ? sectionStep.status === "GENERATING"
                ? "Confirming..."
                : "Confirm"
              : sectionStep.status === "GENERATING"
              ? "Updating Lesson"
              : "Update Lesson"}
          </Button>
        </Flex>
      );
    } else if (lesson?.state === "LESSON_SCRIPT_COMPLETED") {
      return (
        <Button
          type="primary"
          loading={sectionStep.status === "GENERATING"}
          onClick={() => onSubmit(type, step.type)}
          className="brief-button"
        >
          {sectionStep.status === "GENERATING"
            ? "Generating Script"
            : "Confirm Submit"}
        </Button>
      );
    }
  };

  useEffect(() => {
    const containerDiv = container?.current;

    if (!containerDiv) return; // Ensure containerDiv is available

    // Wait for stepContainer to be available
    const observerForContainer = new MutationObserver(() => {
      const stepContainerDiv = stepContainer?.current;

      if (stepContainerDiv) {
        const handleScrollToBottom = () => {
          containerDiv.scroll({
            top: containerDiv.scrollHeight,
            behavior: "smooth",
          });
        };

        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length > 0) {
              handleScrollToBottom();
            }
          });
        });

        // Observe the step container only if it's a valid Node
        if (stepContainerDiv instanceof Node) {
          observer.observe(stepContainerDiv, {
            childList: true,
            subtree: true,
          });
        }

        // Clean up the observer when component unmounts or stepContainerDiv changes
        return () => {
          observer.disconnect();
        };
      }
    });

    // Observe the container until stepContainer is rendered
    if (containerDiv instanceof Node) {
      observerForContainer.observe(containerDiv, {
        childList: true,
        subtree: true,
      });
    }

    // Clean up the observer when component unmounts
    return () => {
      observerForContainer.disconnect();
    };
  }, [container, stepContainer]);

  return (
    <Row className="course-detail">
      <Col span={selectedScript ? 16 : 24} className="lesson-detail-container">
        <Row>
          <Col span={24}>
            <Flex className="course-detail-header" justify="space-between">
              <span className="course-header-title">{step.title}</span>
            </Flex>
          </Col>
        </Row>

        <Row
          vertical
          className="course-content"
          style={{ flex: 1 }}
          ref={container}
        >
          <Col span={24}>
            <Flex vertical gap={16} flex={1} style={{ height: "fit-content" }}>
              <Form
                onFieldsChange={(e) => {
                  if (e.length > 0) {
                    setUpdated((prev) => {
                      const fieldName = e[0]?.name?.[0];
                      if (prev && prev?.includes(fieldName)) {
                        return prev;
                      }
                      return prev ? [...prev, fieldName] : [fieldName];
                    });
                  }
                  setFinal(false);
                }}
                form={lessonScriptForm}
                layout="vertical"
                className="course-detail-form"
                initialValues={sectionStep.id === lesson?._id ? lesson : ""}
              >
                {questions
                  ?.filter((item, index) => index < 2)
                  .map((item, index) => (
                    <Form.Item
                      required={false}
                      className={`${index === 0 ? "title" : "userContent"}`}
                      label={
                        <span className="question">
                          <Flex vertical flex={1} gap={10}>
                            <span className="lesson-title">
                              Lesson {index === 0 ? "Title" : "Summary"}
                            </span>
                            {index === 0 ? (
                              <div>
                                {item.message.split("LOREM IPSUM")[0]}
                                <i>{lesson?.title || "LOREM IPSUM"}</i>
                                {item.message.split("LOREM IPSUM")[1]}
                              </div>
                            ) : (
                              item.message
                            )}
                          </Flex>
                        </span>
                      }
                      name={`${index === 0 ? "title" : "userContent"}`}
                      rules={[
                        {
                          required: true,
                          message: `Please enter ${
                            index === 0 ? "title" : "content"
                          }`,
                        },
                      ]}
                    >
                      {index === 0 ? (
                        <Input
                          readOnly={scriptUpdateStages.includes(currentStatus)}
                          className="answer-input"
                          placeholder="Enter your answer here"
                          value={lesson?.title}
                        />
                      ) : (
                        <TextArea
                          readOnly={scriptUpdateStages.includes(currentStatus)}
                          spellCheck={false}
                          autoSize={{ minRows: 8, maxRows: 8 }}
                          placeholder="Enter your answer here"
                          value={lesson?.userContent}
                        />
                      )}
                    </Form.Item>
                  ))}
              </Form>

              {!scriptUpdateStages.includes(currentStatus) && (
                <>
                  <Divider style={{ margin: "0" }}>OR</Divider>
                  <LessonDocument
                    courseId={courseId}
                    lessonId={sectionStep?.id}
                    updateLesson={updateLesson}
                  />
                </>
              )}
              <Flex vertical gap={16} ref={stepContainer}>
                {lesson.userContent &&
                  questions
                    ?.filter((item, index) => {
                      const hasStepResults =
                        lesson?.stepResults &&
                        lesson?.stepResults[item.lessonStep];
                      if (currentStatus === "created") {
                        return false;
                      }
                      if (
                        hasStepResults &&
                        lesson?.stepResults[item.lessonStep]
                      ) {
                        return true;
                      }
                      if (
                        !nextStepShown &&
                        index >= 2 &&
                        !lesson?.stepResults[item.lessonStep]
                      ) {
                        nextStepShown = true;
                        return true;
                      }

                      return false;
                    })
                    .map((item) => (
                      <Flex
                        vertical
                        gap={20}
                        className="script-result-container"
                        key={item._id}
                      >
                        <span>{item.message}</span>
                        <Flex
                          gap={10}
                          justify="space-between"
                          align="center"
                          className="script-result"
                        >
                          {lesson?.stepResults &&
                            (lesson?.stepResults[item.lessonStep] ||
                              item.lessonStep === section) && (
                              <>
                                <span>{item.lessonStep}</span>
                                <Button
                                  type={
                                    lesson?.stepResults[item.lessonStep]
                                      ? "default"
                                      : "primary"
                                  }
                                  onClick={() => {
                                    setSelectedScript({
                                      step: item.lessonStep,
                                      question: item.message,
                                      script:
                                        lesson?.stepResults[item.lessonStep],
                                      section: item.lessonStep,
                                      status: regenerating.includes(
                                        item.lessonStep
                                      )
                                        ? "REGENERATING"
                                        : "COMPLETED",
                                    });
                                  }}
                                  loading={
                                    !lesson?.stepResults[item.lessonStep]
                                  }
                                >
                                  {lesson?.stepResults[item.lessonStep]
                                    ? "View script"
                                    : "Generating Script"}
                                </Button>
                              </>
                            )}
                        </Flex>
                      </Flex>
                    ))}
              </Flex>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Flex flex={1} className="course-detail-footer" justify="flex-end">
              {renderButton(step.type)}
            </Flex>
          </Col>
        </Row>
      </Col>
      {selectedScript && (
        <Col
          span={8}
          style={{
            height: "100%",
          }}
        >
          <LessonScript
            selectedScript={selectedScript}
            setLesson={setLesson}
            setSelectedScript={setSelectedScript}
            onUpdate={onUpdate}
            setSection={setSection}
            generateScript={generateScript}
            currentStatus={currentStatus}
            steps={lesson?.stepResults}
          />
        </Col>
      )}
    </Row>
  );
};

export default LessonBrief;
