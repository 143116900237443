import { Button, Flex, Modal, Upload } from "antd";
import React, { useState } from "react";
import "./modal.css";
import { ReactComponent as UploadIcon } from "../../assets/images/upload.svg";
import { ReactComponent as Image } from "../../assets/images/placeholder.svg";
import NotificationInstance from "../../services/notificationServices";
import { uploadProjectDocument } from "../../api/projectServices";
import { DeleteOutlined } from "@ant-design/icons";

const UploadProjectDocument = ({
  isModalOpen,
  handleOk,
  handleCancel,
  projectId,
}) => {
  const [uploadFileData, setUploadFileData] = useState([]); // Array for multiple files
  const [uploading, setUploading] = useState(false);
  const [failedUploads, setFailedUploads] = useState([]); // State for failed uploads

  const handleChange = (info) => {
    const fileList = info.fileList;
    const validFiles = fileList.filter((file) => {
      const isSupportedFileType =
        file.type === "application/msword" || // DOC
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // DOCX
        file.type === "text/plain" || // TXT
        file.type === "application/pdf"; // PDF

      const fileSize = file.size / 1024 / 1024; // Convert to MB

      if (!isSupportedFileType) {
        NotificationInstance.warning({
          message: `${file.name} is not a supported file type!`,
        });
        return false;
      }

      if (fileSize > 100) {
        NotificationInstance.warning({
          message: `${file.name} must be smaller than 100MB!`,
        });
        return false;
      }

      return true;
    });
    setUploadFileData(validFiles);
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onSubmit = () => {
    if (uploadFileData.length > 0) {
      setUploading(true);
      const fileData = new FormData();
      uploadFileData.forEach((file) => {
        fileData.append("projectFile", file.originFileObj || file);
      });
      uploadProjectDocument(projectId, fileData)
        .then((res) => {
          onModalClose();
          NotificationInstance.success({
            message: "Upload Successful",
            description: "The files were uploaded successfully.",
          });
          handleOk(res?.data);
        })
        .catch((err) => {
          NotificationInstance.error({
            message: "Upload Failed",
            description: "Failed to upload the files. Please try again.",
          });
          console.error(err);
        })
        .finally(() => {
          setUploading(false);
        });
    } else {
      NotificationInstance.warning({
        message: "Please upload a document!",
      });
    }
  };

  const beforeUpload = (file) => {
    console.log(file);

    const isSupportedFileType =
      file.type === "application/msword" || // DOC
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // DOCX
      file.type === "text/plain" || // TXT
      file.type === "application/pdf"; // PDF

    const fileSize = file.size / 1024 / 1024;

    if (!isSupportedFileType) {
      setFailedUploads((prev) => [
        { name: file.name, reason: "Unsupported file type" },
        ...prev,
      ]);
      return false;
    }

    if (fileSize > 100) {
      setFailedUploads((prev) => [
        { name: file.name, reason: "File size exceeds 100MB" },
        ...prev,
      ]);
      return false;
    }

    return true;
  };

  const handleRemove = (index, type) => {
    if (type === "failed") {
      const newFileList = failedUploads.slice();
      newFileList.splice(index, 1);
      setFailedUploads(newFileList);
    } else {
      const newFileList = uploadFileData.slice();
      newFileList.splice(index, 1);
      setUploadFileData(newFileList);
    }
  };

  const formatSize = (size) => {
    if (size < 1024) {
      return `${size}B`;
    } else if (size < 1024 * 1024) {
      return `${Math.floor(size / 1024)}Kb`;
    } else {
      return `${Math.floor(size / (1024 * 1024))}Mb`;
    }
  };

  const onModalClose = () => {
    handleCancel();
    setUploadFileData([]); // Reset the file data when modal closes
    setFailedUploads([]);
  };

  console.log(uploadFileData);

  return (
    <Modal
      centered
      width={600}
      title="Add Document"
      open={isModalOpen}
      onCancel={onModalClose}
      footer={
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit} disabled={uploading}>
            {uploading ? "Uploading..." : "Upload"}
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      }
    >
      {uploading ? (
        <div className="thumbnail-upload">
          <div className="video-loader">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        <div className="thumbnail-upload">
          {uploadFileData?.length > 0 || failedUploads?.length > 0 ? (
            <Flex
              vertical
              gap={10}
              flex={1}
              style={{ width: "100%" }}
              className="project-file-container"
            >
              {uploadFileData?.map((item, index) => (
                <Flex gap={10} justify="space-between">
                  <Flex gap={10} className="doument-detail">
                    <Image />
                    <Flex
                      vertical
                      align="flex-start"
                      justify="center"
                      className="doument-detail"
                    >
                      {item?.name && (
                        <span className="document-name">{item?.name}</span>
                      )}
                      {item?.size && (
                        <span className="image-size">
                          {formatSize(item?.size)}
                        </span>
                      )}
                    </Flex>
                  </Flex>
                  <DeleteOutlined
                    className="delete-project-file"
                    onClick={() => handleRemove(index)}
                  />
                </Flex>
              ))}
              {failedUploads?.map((item, index) => (
                <Flex gap={10} justify="space-between">
                  <Flex gap={10} className="doument-detail">
                    <Image />
                    <Flex
                      vertical
                      align="flex-start"
                      justify="center"
                      className="doument-detail"
                    >
                      {item?.name && (
                        <span className="document-name">{item?.name}</span>
                      )}
                      {item?.reason && (
                        <span className="failed-doc">{item?.reason}</span>
                      )}
                    </Flex>
                  </Flex>
                  <DeleteOutlined
                    className="delete-project-file"
                    onClick={() => handleRemove(index, "failed")}
                  />
                </Flex>
              ))}
            </Flex>
          ) : (
            <>
              <UploadIcon />
              <span className="browse">Browse and upload a Document</span>
              <span className="supported-files">
                DOC, DOCX, TXT, or PDF files
              </span>
              <span className="supported-files">Maximum file size: 100MB</span>
              <span className="supported-files">Maximum word count: 30K</span>
            </>
          )}

          <Upload
            fileList={uploadFileData}
            listType="picture"
            multiple={true}
            onChange={handleChange}
            customRequest={customRequest}
            onRemove={handleRemove}
            beforeUpload={beforeUpload}
            showUploadList={false}
            accept=".doc,.docx,.txt,.pdf"
          >
            <Button>
              {uploadFileData.length > 0 ? "Add more files" : "Browse Files"}
            </Button>
          </Upload>
        </div>
      )}
    </Modal>
  );
};

export default UploadProjectDocument;
