import React, { useState } from "react";
import { ConfigProvider, Empty, Table } from "antd";
import "./organizationsList.css";
import { organizationDetails } from "../../api/adminService";
import AddOrganizationModal from "../modals/addOrganizationModal";

const columns = (handleOrganizationClick) => [
  {
    title: "Name",
    dataIndex: "name",
    width: "25%",
    render: (text, record) => (
      <span
        style={{ fontWeight: "bold", cursor: "pointer", color: "black" }}
        onClick={() => handleOrganizationClick(record)}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "20%",
  },
  {
    title: "Created by",
    dataIndex: "createdBy",
    width: "25%",
    render: (text, record) => (
      <span>
        {text?.firstName} {text?.lastName}
      </span>
    ),
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    width: "20%",
  },
];

const OrganizationTable = ({ data, loading, setRefresh, refresh }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [organizationData, setOrganizationData] = useState(null);
  const [detailsModal, setDetailsModal] = useState(false);

  const handleOpenModal = (organizationId) => {
    setModalVisible(true);
    setDetailsModal(true);
    fetchOrganizationDetails(organizationId);
  };

  const handleOrganizationClick = (organization) => {
    handleOpenModal(organization.key);
  };

  const fetchOrganizationDetails = (organizationId) => {
    organizationDetails(organizationId)
      .then((response) => {
        setOrganizationData(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching organization details", error);
      });
  };

  return (
    <div className="organization-content-list">
      <ConfigProvider
        theme={{
          token: {
            controlInteractiveSize: 20,
          },
          components: {
            Table: {
              headerBorderRadius: "8px",
              headerColor: "#6A6A6A",
            },
          },
        }}
      >
        <Table
          pagination={false}
          columns={columns(handleOrganizationClick)}
          dataSource={data}
          rowKey="id"
          loading={{
            spinning: data?.length > 0 ? loading : false,
            indicator: (
              <div style={{ height: 40, width: 40 }} className="loader"></div>
            ),
          }}
          locale={{
            emptyText: loading ? (
              <Empty description="Loading...." />
            ) : (
              <Empty description="No Organizations Found" />
            ),
          }}
        />
      </ConfigProvider>
      <AddOrganizationModal
        isModalOpen={modalVisible}
        handleCancel={() => {
          setModalVisible(false);
        }}
        setDetailsModal={setDetailsModal}
        detailsModal={detailsModal}
        setOrganizationData={setOrganizationData}
        organizationData={organizationData}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
};
export default OrganizationTable;
