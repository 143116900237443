import React, { useEffect, useState } from "react";
import { FloatButton } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import ChatBot from "./chatbot";
import {
  createGeneralChannel,
  getGeneralChannel,
} from "../../api/profileServices";

const ChatComponent = ({ userDetails = {}, type = "Video" }) => {
  const [channel, setChannel] = useState(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  useEffect(() => {
    getGeneralChannel()
      .then((res) => {
        setChannel(res.data._id);
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.data?.message === "Failed to fetch the channel") {
          createGeneralChannel()
            .then((res) => {
              setChannel(res.data._id);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
  }, []);

  const toggleChatbot = () => {
    setIsChatbotOpen((prev) => !prev);
  };

  return (
    <div>
      <FloatButton
        icon={<CommentOutlined />}
        tooltip={<div>Chat</div>}
        className="chatbot-button"
        onClick={toggleChatbot}
      />

      <div className={`chatbot-popup ${isChatbotOpen ? "open" : "closed"}`}>
        {channel && (
          <ChatBot channel={channel} type={type} userDetails={userDetails} />
        )}
      </div>
    </div>
  );
};

export default ChatComponent;
