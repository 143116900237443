import React, { useEffect, useState } from "react";
import { ConfigProvider, Button, Typography, Input, Popconfirm } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PRIMARY_COLOR } from "../../utils/colors";
import { useParams } from "react-router-dom";
import "./organizationDetails.css";
import {
  organizationDetails,
  updateOrganizationDetails,
} from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import { StatusTag } from "../../components/tags/tags";

const { Title } = Typography;
const { TextArea } = Input;

const OrganizationDetails = () => {
  const params = useParams();
  const organizationId = params.organizationId;
  const [organizationData, setOrganizationData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (id) => {
      setLoading(true);
      try {
        const res = await organizationDetails(id);
        setOrganizationData(res?.data);
        setEditData(res?.data);
      } catch (error) {
        console.error("Error fetching organization details:", error);
      } finally {
        setLoading(false);
      }
    };
    if (organizationId) {
      fetchData(organizationId);
    }
  }, [organizationId]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditData(organizationData);
    setErrors({});
  };

  const handleChange = (field, value) => {
    setEditData((prevData) => {
      const newData = { ...prevData };
      const keys = field.split(".");
      if (keys.length === 2) {
        newData[keys[0]] = { ...newData[keys[0]], [keys[1]]: value };
      } else {
        newData[field] = value;
      }
      return newData;
    });
  };

  const validateFields = () => {
    const newErrors = {};

    if (!editData.name?.trim()) {
      newErrors.name = "Organization name is required.";
    }
    if (!editData.adminEmail?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      newErrors.adminEmail = "Please enter a valid admin email address.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }
    try {
      const response = await updateOrganizationDetails(
        organizationId,
        editData
      );
      if (!response.errorCode) {
        setIsEditing(false);
        setOrganizationData(editData);
        NotificationInstance.success({
          message: "Organization edited successfully",
        });
      } else {
        console.error("Failed to update organization:", response);
      }
    } catch (error) {
      console.error("Error submitting edited organization:", error);
      NotificationInstance.error({
        message: "Error editing organization",
        description: error.response.data.error,
      });
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <LoadingOutlined style={{ fontSize: "40px" }} />
      </div>
    );
  }

  return (
    <div className="organization-details-container">
      <div className="orgDetailsHeading">
        <Title level={3}>{organizationData?.name}</Title>
      </div>

      <ConfigProvider
        theme={{
          token: {
            fontFamily: '"Poppins", sans-serif',
            Button: {
              colorPrimary: PRIMARY_COLOR,
              colorPrimaryHover: PRIMARY_COLOR,
              colorPrimaryActive: PRIMARY_COLOR,
              primaryShadow: "none",
              controlHeight: 45,
            },
          },
        }}
      >
        <div className="details-section">
          <div className="detail-item">
            <p className="label">Name:</p>
            {isEditing ? (
              <>
                <Input
                  value={editData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </>
            ) : (
              <p className="value">{organizationData?.name}</p>
            )}
          </div>
          {errors.name && <p className="error">{errors.name}</p>}

          <div className="detail-item">
            <p className="label">Description:</p>
            {isEditing ? (
              <TextArea
                large
                rows={4}
                value={editData.description}
                onChange={(e) => handleChange("description", e.target.value)}
                style={{
                  width: "750px",
                  border: "1px solid #ccc",
                  color: "black",
                }}
              />
            ) : (
              <p className="value">{organizationData?.description}</p>
            )}
          </div>
          {errors.description && <p className="error">{errors.description}</p>}

          <div className="detail-item">
            <p className="label">Address:</p>
            {isEditing ? (
              <>
                <Input
                  placeholder="Street"
                  value={editData?.address?.street}
                  onChange={(e) =>
                    handleChange("address.street", e.target.value)
                  }
                />
                <Input
                  placeholder="City"
                  value={editData?.address?.city}
                  onChange={(e) => handleChange("address.city", e.target.value)}
                />
                <Input
                  placeholder="State"
                  value={editData?.address?.state}
                  onChange={(e) =>
                    handleChange("address.state", e.target.value)
                  }
                />
                <Input
                  placeholder="Postal Code"
                  value={editData?.address?.postalCode}
                  onChange={(e) =>
                    handleChange("address.postalCode", e.target.value)
                  }
                />
                <Input
                  placeholder="Country"
                  value={editData?.address?.country}
                  onChange={(e) =>
                    handleChange("address.country", e.target.value)
                  }
                />
              </>
            ) : (
              <p className="value">
                {organizationData?.address?.street},{" "}
                {organizationData?.address?.city},
                {organizationData?.address?.state},{" "}
                {organizationData?.address?.postalCode},
                {organizationData?.address?.country}
              </p>
            )}
          </div>
          {errors.postalCode && isEditing && (
            <div className="error-message">
              <p className="error">{errors.postalCode}</p>
            </div>
          )}
          {errors.address && isEditing && (
            <div className="error-message">
              <p className="error">{errors.address}</p>
            </div>
          )}
          <div className="detail-item">
            <p className="label">Phone:</p>
            {isEditing ? (
              <>
                <Input
                  value={editData.phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                />
              </>
            ) : (
              <p className="value">{organizationData?.phone}</p>
            )}
          </div>
          {errors.phone && <p className="error">{errors.phone}</p>}

          <div className="detail-item">
            <p className="label">Organization Email:</p>
            {isEditing ? (
              <>
                <Input
                  value={editData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </>
            ) : (
              <p className="value">{organizationData?.email}</p>
            )}
          </div>
          {errors.email && <p className="error">{errors.email}</p>}

          <div className="detail-item">
            <p className="label">Status:</p>
            <StatusTag status={organizationData?.status} />
            {/* <p className="value">{organizationData?.status}</p> */}
          </div>

          <div className="detail-item">
            <p className="label">Created By:</p>
            <p className="value">{organizationData?.createdBy}</p>
          </div>

          <div className="detail-item">
            <p className="label">Created At:</p>
            <p className="value">
              {new Date(organizationData?.createdAt).toLocaleString()}
            </p>
          </div>
        </div>

        <div className="button-container">
          {isEditing ? (
            <>
              <Button
                type="default"
                className="cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Popconfirm
                overlayStyle={{ width: 400 }}
                title="Edit Organization"
                description="Are you sure you want to update the Organization details"
                onConfirm={handleSubmit}
              >
                <Button type="primary" className="submit-button">
                  Submit
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Button
              type="primary"
              className="edit-button"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
        </div>
      </ConfigProvider>
    </div>
  );
};

export default OrganizationDetails;
