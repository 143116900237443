import { Button, Col, Flex, Input, Menu, Row, Dropdown } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  ReloadOutlined,
  LoadingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import ReactAudioPlayer from "react-audio-player";
import {
  generateUseCaseAudio,
  getMyAvatars,
  updateUseCaseAudio,
} from "../../../api/adminService";
import { scriptUpdateStages, videoStages } from "../../../utils/format";
import AudioProfileModal from "../../modals/audioProfilesModal";
import AvatarProfileModal from "../../modals/avatarProfileModal";
import "./audioUpdation.css";
import VoiceSettings from "../../modals/voiceSettings";
import NotificationInstance from "../../../services/notificationServices";
import { courseStageTypes } from "../../../utils/constant";

const AudioUpdation = ({
  usecaseData,
  scriptParts,
  useCaseId,
  setScriptParts,
  setStatus,
  status,
  setCurrent,
  selectedAudioProfile,
  setSelectedAudioProfile,
  setSelectedAvatarProfile,
  audioProfiles,
  setVoiceSetting,
  voiceSetting,
  model
}) => {
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const [audioProfileModal, setAudioProfileModal] = useState(false);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState();
  const [updateItem, setupdateItem] = useState();
  const { TextArea } = Input;
  const audioRefs = useRef([]);

  const handleSentenceChange = async (index, e) => {
    if (scriptParts[index]) {
      const updatedScriptParts = scriptParts.map((part, i) =>
        i === index ? { ...part, text: e.target.value } : part
      );
      setScriptParts(updatedScriptParts);
    }
  };

  const handleRefresh = async (index, settings) => {
    if (scriptParts[index]) {
      const text = scriptParts[index].text;

      if (!text || text.trim() === "") {
        NotificationInstance.error({
          message: "Please enter the audio text",
        });
        return;
      }
      const updatedScriptParts = scriptParts.map((part, i) =>
        i === index ? { ...part, url: null, voiceSettings: settings } : part
      );
      setScriptParts(updatedScriptParts);
      const data = {
        audioData: {
          text: updatedScriptParts[index]?.text,
        },
        voiceSettings: settings,
        audioId: updatedScriptParts[index]?._id,
      };
      console.log(updatedScriptParts[index]);
      updateUseCaseAudio(useCaseId, data, selectedAudioProfile)
        .then((res) => {
          setStatus("AUDIO_GENERATION_INPROGRESS");
        })
        .catch((err) => {});
    }
  };

  const handlePlay = (index) => {
    if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
      const previousAudio = audioRefs.current[currentPlayingIndex];
      if (previousAudio) {
        previousAudio.audioEl.current.pause();
      }
    }
    setCurrentPlayingIndex(index); // Update the current playing index
  };

  useEffect(() => {
    if (usecaseData && status === "LESSON_SCRIPT_SUBMITTED") {
      console.log(status);
      generateUseCaseAudio(useCaseId, selectedAudioProfile)
        .then((res) => {
          setStatus("AUDIO_GENERATION_INPROGRESS");
        })
        .catch((err) => {});
    }
  }, [usecaseData, status]);

  const handleCloseModal = () => {
    setAudioProfileModal(false);
  };

  const handleAudioProfileChange = (newProfile) => {
    setSelectedAudioProfile(newProfile);
  };

  const handleAvatarProfileChange = (newAvatar) => {
    setSelectedAvatarProfile(newAvatar);
  };

  const updateAudio = () => {
    const updatedScriptParts = scriptParts.map((part, i) => ({
      ...part,
      url: "",
    }));
    setScriptParts(updatedScriptParts);
    setStatus("LESSON_SCRIPT_SUBMITTED");
    setVoiceSetting((prev) => ({
      settings: null,
      voiceId: prev?.voiceId,
    }));
  };

  const handleUpdateAllAudios = async (settings) => {
    // Check if any chunk has empty text or text that contains only whitespace
    const invalidChunk = scriptParts.find(
      (part) => !part.text || part.text.trim() === ""
    );

    if (invalidChunk) {
      // Show error if any chunk has invalid text
      NotificationInstance.error({
        message:
          "Please enter the audio text for all sections before updating.",
      });
      return; // Stop execution if there's an invalid text
    }

    // Proceed if all scriptParts have valid text
    const updatedScriptParts = scriptParts.map((part) => ({
      ...part,
      url: null,
    }));

    setScriptParts(updatedScriptParts);

    const data = {
      voiceSettings: settings,
      voiceId: voiceSetting?.voiceId,
    };

    generateUseCaseAudio(useCaseId, "", data)
      .then((res) => {
        setVoiceSetting((prev) => ({
          settings: settings,
          voiceId: prev?.voiceId,
        }));
        setStatus("AUDIO_GENERATION_INPROGRESS");
      })
      .catch((err) => {
        console.error(err); // Handle error as needed
      });
  };

  const updateAudios = (type, settings) => {
    setIsModalOpen(false);
    if (type === "all") {
      handleUpdateAllAudios(settings);
    } else {
      handleRefresh(type, settings);
    }
  };
  const handleMenuClick = (model) => {
    if (model === "heygen") {
      setCurrent((prev) => prev + 1);
    } else if (model === "leapRoad") {
      getMyAvatars()
        .then((res) => {
          const defaultavatar = res?.data?.find((item) => item.isDefault);
          if (defaultavatar) {
            setSelectedAvatarProfile({
              avatarId: defaultavatar?._id,
              type: "leapRoad",
            });
            setCurrent((prev) => prev + 1);
          } else {
            NotificationInstance.warning({
              message: "Please select a default leaproad avatar to generate video",
            });
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <>
      <Row className="usecase-container">
        <Col span={24}>
          <div
            className="audio-verification-container"
            style={{ width: "100%" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <VoiceSettings
                isModalOpen={isModalOpen}
                handleCancel={() => setIsModalOpen()}
                updateItem={updateItem}
                handleOk={updateAudios}
              />
              {scriptParts?.map((sentence, index) => (
                <React.Fragment key={index}>
                  <Flex vertical gap={20} className="lesson-chunk-container">
                    <TextArea
                      spellCheck={false}
                      readOnly={videoStages.includes(status)}
                      autoSize={{ minRows: 5, maxRows: 6 }}
                      value={sentence.text}
                      onChange={(e) => handleSentenceChange(index, e)}
                    />

                    {sentence?.url ? (
                      <>
                        <Flex gap={10} align="center">
                          <ReactAudioPlayer
                            src={sentence?.url} // Ensure you're accessing the correct property (url)
                            ref={(element) =>
                              (audioRefs.current[index] = element)
                            }
                            controls
                            controlsList="noplaybackrate nodownload"
                            style={{
                              // display: currentAudio === index ? "block" : "none",
                              minHeight: 54,
                            }}
                            onPlay={() => handlePlay(index)}
                          />
                          {!videoStages.includes(status) && (
                            <Button
                              gap={10}
                              onClick={() => handleRefresh(index)}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                              }}
                            >
                              <ReloadOutlined
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              />
                              <span>Regenerate</span>
                            </Button>
                          )}
                          {!videoStages.includes(status) && (
                            <Flex gap={10} align="center">
                              <Button
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setupdateItem({
                                    item: index,
                                    settings: sentence?.voiceSettings,
                                  });
                                }}
                              >
                                Voice Settings
                              </Button>
                            </Flex>
                          )}
                        </Flex>
                      </>
                    ) : (
                      <Flex gap={20}>
                        <LoadingOutlined
                          disabled
                          style={{
                            fontSize: "20px",
                            cursor: "not-allowed",
                            marginLeft: "10px",
                          }}
                        />
                        <span className="generating">Generating Audio...</span>
                      </Flex>
                    )}
                  </Flex>
                </React.Fragment>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="usecase-footer" span={24}>
          <Flex flex={1} gap={20} justify="space-between">
            {scriptUpdateStages.includes(status) &&
              status !== "AUDIO_GENERATION_INPROGRESS" && (
                <Button
                  onClick={() => setCurrent((prev) => prev - 1)}
                  className="footer-button"
                >
                  Back
                </Button>
              )}
            <Flex gap={10}>
              {!videoStages.includes(status) &&
                status !== courseStageTypes.AUDIO_GENERATION_INPROGRESS && (
                  <Flex gap={10} align="center">
                    <Button
                      onClick={() => {
                        setIsModalOpen(true);
                        setupdateItem({
                          item: "all",
                          settings: voiceSetting?.settings,
                        });
                      }}
                    >
                      Voice Settings
                    </Button>
                  </Flex>
                )}
              {status === "AUDIO_GENERATION_COMPLETED" && (
                <Flex gap={10}>
                  <Button onClick={() => setAudioProfileModal(true)}>
                    Change Voice Profile
                  </Button>
                  {model!=="leapRoad"&&<Button onClick={() => setAvatarModalVisible(true)}>
                    Select Avatar for Video
                  </Button>}
                  <Flex className="generate-video-types">
                    {" "}
                    <Button
                      onClick={() => handleMenuClick(model)}
                      type="primary"
                    >
                      Generate Video
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Flex>

            {videoStages.includes(status) && (
              <Button onClick={() => setCurrent((prev) => prev + 1)}>
                Next
              </Button>
            )}
          </Flex>
        </Col>
      </Row>
      <AudioProfileModal
        visible={audioProfileModal}
        onClose={handleCloseModal}
        onSelect={handleAudioProfileChange}
        audioProfiles={audioProfiles}
        updateAudio={updateAudio}
        status={status}
      />
      <AvatarProfileModal
        visible={avatarModalVisible}
        onClose={() => setAvatarModalVisible(false)}
        onSelect={handleAvatarProfileChange}
      />
    </>
  );
};

export default AudioUpdation;
