import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Input,
  Flex,
  Divider,
  Select,
  Form,
  Tooltip,
  Col,
  Row,
  Modal,
  Popconfirm,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { videoStages } from "../../../utils/format";
import {
  convertFromRaw,
  convertToRaw,
  CompositeDecorator,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import {
  courseStageTypes,
  createBlocksFromEntities,
  rawContent,
  TokenSpan,
} from "../../../utils/constant";
import UploadVideoPresentation from "../../modals/uploadVideoPresentation";
import { capitalizeInput, scriptStages } from "../../../utils/format";
import {
  getStockVideos,
  updateUseCase,
  updateUsecaseScript,
  uploadUseCasePPT,
} from "../../../api/adminService";

import { ReactComponent as Slide } from "../../../assets/images/slide.svg";
import { ReactComponent as Video } from "../../../assets/images/videoPrompt.svg";
import { ReactComponent as Subtitle } from "../../../assets/images/subtitle.svg";
import NotificationInstance from "../../../services/notificationServices";
import AudioProfileModal from "../../modals/audioProfilesModal";

const items = [
  { type: "SUBTITLE", icon: <Subtitle />, title: "KEYWORD" },
  { type: "VIDEO", icon: <Video />, title: "VIDEO" },
  { type: "SLIDE", icon: <Slide />, title: "SLIDE" },
];

const LessonScriptUpdation = ({
  setChunks,
  pages,
  currentStatus,
  setScriptUpdated,
  slidePreview,
  lesson,
  setLesson,
  lessonId,
  setContentStep,
  scriptUpdated,
  scriptUpdateStages,
  setSlidePreview,
  setPages,
  file,
  setFile,
  setcurrentStatus,
  model,
  setModel,
  setSelectedAudioProfile,
  audioProfiles,
}) => {
  const textAreaRefs = useRef([]);
  const editorRef = useRef(null);
  const [editorChanged, setEditorChanged] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [scriptUpdate, setScriptUpdate] = useState();
  const [inputValue, setInputValue] = useState("");
  const [selectedEntityType, setSelectedEntityType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generating, setgenerating] = useState(false);
  const [videos, setVideos] = useState();
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectedState, setselectedState] = useState();
  const [videoLoading, setVideoLoading] = useState(false);
  const [subtitleText, setSubtitleText] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [selectedTexts, setselectedTexts] = useState(false);
  const rightColRef = useRef(null); // Ref for the right-side column
  const buttonsRef = useRef(null); // Ref for the buttons
  // const [file, setFile] = useState("");
  const [showRemove, setshowRemove] = useState(false);
  const [userSelectedVideo, setUserSelectedVideo] = useState(false);
  // const [pages, ] = useState(false);
  const [isEntityAtSelection, setIsEntityAtSelection] = useState(false);
  const [audioProfileModal, setAudioProfileModal] = useState(false);

  useEffect(() => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const anchorKey = selection.getAnchorKey();
      const currentContent = editorState.getCurrentContent();
      const currentBlock = currentContent.getBlockForKey(anchorKey);

      const start = selection.getStartOffset();
      const end = selection.getEndOffset();
      const selectedText = currentBlock.getText().slice(start, end);
      const normalizedText = selectedText.replace(/\s+/g, " ").trim();
      const wordCount =
        normalizedText.length > 0 ? normalizedText.split(" ").length : 0;

      if (wordCount >= 6) {
        setselectedTexts(selectedText);
      } else {
        setselectedTexts(null);
      }
    } else {
      setselectedTexts(null);
    }
  }, [editorState]);

  useEffect(() => {
    if (lesson) {
      const decorator = new CompositeDecorator([
        {
          strategy: getEntityStrategy("SEGMENTED"),
          component: TokenSpan,
        },
        {
          strategy: getEntityStrategy("MUTABLE"),
          component: TokenSpan,
        },
        {
          strategy: getEntityStrategy("SEGMENTED"),
          component: TokenSpan,
        },
      ]);
      let blocks;
      // Create blocks from script parts
      if (lesson.scriptParts && lesson.scriptParts.length > 0) {
        blocks = createBlocksFromEntities(lesson.scriptParts);
      } else if (lesson.finalScript) {
        const data = [
          {
            text: lesson.finalScript,
            order: 0,
            type: "normal",
          },
        ];
        blocks = createBlocksFromEntities(data);
      } else {
        blocks = rawContent;
      }
      const contentState = convertFromRaw(blocks);

      // Update the editor state
      setEditorState(EditorState.createWithContent(contentState, decorator));
    }
  }, [lesson?.scriptParts]);
  const handleEntityTypeChange = (type) => {
    if (type === "SLIDE" && !pages) {
      setIsModalOpen(true);
      setInputValue(1);
    } else {
      // setEditorState(RichUtils.toggleInlineStyle(editorState, "HIGHLIGHT"));
      setSelectedEntityType(type);
      setScriptUpdate(type);
      setInputValue(type?.toUpperCase() === "SLIDE" ? 1 : "");
      getEntityAtSelection(editorState, type);
    }
  };
  const renderScriptContent = (type) => {
    if (type.toUpperCase() === "SUBTITLE") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%" }}
        >
          <Input.TextArea
            value={inputValue}
            readOnly={videoStages.includes(currentStatus)}
            // autoFocus={true}
            onChange={(e) => setInputValue(e.target.value)}
            style={{ height: "100%", resize: "none" }}
            className="script-input"
            placeholder="Enter the keyword..."
          />
        </Flex>
      );
    } else if (type.toUpperCase() === "SLIDE") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%", overflowY: "hidden" }}
        >
          <img
            style={{ maxWidth: "100%", overflowY: "hidden" }}
            src={
              slidePreview
                ? inputValue
                  ? slidePreview[inputValue]
                  : slidePreview[1]
                : ""
            }
            alt=""
          />
          <Select
            disabled={videoStages.includes(currentStatus)}
            value={inputValue ? inputValue : 1}
            style={{ height: 40, width: "100%", overflowY: "hidden" }}
            options={generateOptions(pages)}
            placeholder="Select slide number"
            onChange={(val) => {
              setInputValue(val);
            }}
          />
        </Flex>
      );
    } else if (type.toUpperCase() === "VIDEO") {
      return (
        <Flex
          vertical
          justify="flex-start"
          align="flex-start"
          gap={20}
          style={{ height: "100%" }}
        >
          {
            videos ? (
              <Row style={{ width: "100%" }} gutter={[10, 10]}>
                {videos
                  ?.filter(
                    (video, index, self) =>
                      index === self.findIndex((v) => v.id === video.id) // Filters unique by `id`
                  )
                  .map((item) => (
                    <Col span={24}>
                      <Flex
                        vertical
                        gap={5}
                        style={{
                          padding: 10,
                          border: "1px solid",
                          borderRadius: 6,
                        }}
                      >
                        <img
                          src={item.image}
                          alt="img"
                          style={{
                            width: "100%",
                            height: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => handleThumbnailClick(item)}
                        />
                        {/* <div style={{
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontSize: "24px",
                      cursor: "pointer",
                      opacity: 0.9
                    }} 
                    onClick={() => handleThumbnailClick(item)} 
                    >
                      <Play />
                    </div> */}
                        <Button
                          type={
                            item?.id === selectedVideo?.id
                              ? "primary"
                              : "default"
                          }
                          onClick={() => setSelectedVideo(item)}
                        >
                          {item?.id === selectedVideo?.id
                            ? "Selected"
                            : "Select"}
                        </Button>
                      </Flex>
                    </Col>
                  ))}
              </Row>
            ) : userSelectedVideo ? (
              <>
                <p>{inputValue}</p>
                <Flex
                  vertical
                  gap={5}
                  style={{
                    padding: 10,
                    border: "1px solid",
                    borderRadius: 6,
                  }}
                >
                  <img
                    src={userSelectedVideo?.image}
                    alt="img"
                    style={{
                      width: "100%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleThumbnailClick(item)}
                  />
                  {/* <div style={{
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontSize: "24px",
                      cursor: "pointer",
                      opacity: 0.9
                    }} 
                    onClick={() => handleThumbnailClick(item)} 
                    >
                      <Play />
                    </div> */}
                </Flex>
              </>
            ) : (
              <Input.TextArea
                value={inputValue}
                readOnly={videoStages.includes(currentStatus)}
                // autoFocus={true}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ height: "100%", resize: "none" }}
                className="script-input"
                placeholder="Enter the video prompt..."
              />
            )
            // <video src={}></video>
          }
          <Modal
            title="Video Player"
            visible={modalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            {selectedVideo && (
              <>
                <video
                  src={selectedVideo?.video_files[0]?.link}
                  controls
                  autoPlay
                  style={{ width: "100%" }}
                />
                {/* <iframe
                  title="Video Player"
                  src={selectedVideo?.video_files[0]?.link}
                  width="100%"
                  height="315"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                /> */}
                <div style={{ marginTop: 20, textAlign: "center" }}>
                  <Button
                    type={selectedVideo?.id ? "primary" : "default"}
                    style={{ width: "100px" }}
                    onClick={() => handleSelect(selectedVideo)}
                  >
                    Select
                  </Button>
                  <Button style={{ marginLeft: 10 }} onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Modal>
        </Flex>
      );
    }
  };

  const handleThumbnailClick = (video) => {
    setSelectedVideo(video);
    setModalVisible(true);
  };

  const handleSelect = (item) => {
    setSelectedVideo(item);
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedVideo(null);
  };

  function getEntityStrategy(mutability) {
    return function (contentBlock, callback, contentState) {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }

        return contentState.getEntity(entityKey).getMutability() === mutability;
      }, callback);
    };
  }
  const onChange = (newEditorState) => {
    const currentContent = editorState.getCurrentContent(); // The current content
    const newContent = newEditorState.getCurrentContent();
    if (currentContent !== newContent) {
      setEditorChanged(true);
      setScriptUpdated(true);
    }
    if (!videoStages.includes(currentStatus)) {
    }
    setEditorState(newEditorState);
    getEntityAtSelection(newEditorState);
  };
  const handleBeforeInput = () => {
    // If current stage is in videoStages, block typing (returning 'handled')
    if (videoStages.includes(currentStatus)) {
      return "handled"; // Prevents typing
    }

    return "not-handled"; // Allows typing
  };

  const handleAudioProfileChange = (newProfile) => {
    setSelectedAudioProfile(newProfile);
  };

  const hasEntityInSubsentence = (editorState) => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const startOffset = selectionState.getStartOffset();
    const endOffset = selectionState.getEndOffset();

    const blocks = contentState.getBlockMap();
    const entitiesInRange = [];
    let entityFound = false;

    // Flag to start processing blocks
    let withinSelection = false;

    // Iterate through all blocks
    blocks.forEach((block, key) => {
      if (key === startKey) withinSelection = true; // Start block
      if (!withinSelection) return; // Skip blocks before startKey

      const blockStartOffset = key === startKey ? startOffset : 0;
      const blockEndOffset = key === endKey ? endOffset : block.getLength();

      // Iterate through the block's offsets in the range
      for (let offset = blockStartOffset; offset < blockEndOffset; offset++) {
        const entityKey = block.getEntityAt(offset);
        if (entityKey) {
          const entityInstance = contentState.getEntity(entityKey);

          // If type is specified, check for a matching type
          if (entityInstance.getType() !== "normal") {
            entityFound = true;
            entitiesInRange.push({
              key: entityKey,
              type: entityInstance.getType(),
              mutability: entityInstance.getMutability(),
              data: entityInstance.getData(),
              offset,
              blockKey: key,
            });
          }
        }
      }

      if (key === endKey) withinSelection = false; // End block
    });

    // Update the flag for entity presence
    if (entityFound) {
      setIsEntityAtSelection(true);
      return {
        found: true,
        entities: entitiesInRange,
      };
    }

    setIsEntityAtSelection(false);
    return { found: false };
  };

  const getEntityAtSelection = (editorState, type) => {
    const selectionState = editorState.getSelection();
    const selectionKey = selectionState.getStartKey();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selectionKey);
    const entityKey = block.getEntityAt(selectionState.getStartOffset());
    const isEntity = hasEntityInSubsentence(editorState);
    if (entityKey && isEntity) {
      const entityInstance = contentState.getEntity(entityKey);
      const entityInfo = {
        type: entityInstance.getType(),
        mutability: entityInstance.getMutability(),
        data: entityInstance.getData(),
      };

      setshowRemove(entityInstance.getData().text);
      if (
        entityInstance.getType().toLowerCase() === "video" &&
        entityInstance.getData().text
      ) {
        setUserSelectedVideo(entityInstance.getData().video);
      }
      if (type) {
        if (entityInstance.getType() === type) {
          setInputValue(entityInstance.getData().text);
        } else {
          setInputValue(type?.toUpperCase() === "SLIDE" ? 1 : "");
        }
      } else {
        setInputValue(entityInstance.getData().text);
      }
      if (type?.toLowerCase() !== "normal") {
        setSelectedEntityType(type ? type : entityInstance.getType());

        setScriptUpdate(
          entityInstance.getType() !== "normal"
            ? type && type.toLowerCase() !== "normal"
              ? type
              : entityInstance.getType()
            : type
        );
      }
    } else {
      // console.log("No entity present at current selection!");
    }
  };
  const fetchStockVideos = (part, promptText) => {
    setVideoLoading(true);
    getStockVideos(lessonId, { partTexts: [part], promptTexts: promptText })
      .then((res) => {
        setVideos(res.data);
        setVideoLoading(false);
      })
      .catch((err) => {
        setVideoLoading(false);
      });
  };

  const setEntityAtSelection = (type) => {
    setScriptUpdated(true);
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const block = contentState?.getBlockForKey(selectionState.getAnchorKey());
    const entityKey = block?.getEntityAt(selectionState.getStartOffset());
    if (entityKey) {
      block.findEntityRanges(
        (character) => character.getEntity() === entityKey,
        (start, end) => {
          const entitySelection = selectionState.merge({
            anchorOffset: start,
            focusOffset: end,
          });
          const entity = contentState?.getEntity(entityKey);
          const entityType = entity?.getType();
          setSubtitleText(block.getText().slice(start, end));
          if (type.toUpperCase() === "VIDEO" && !videos) {
            if (inputValue) {
              setselectedState(selectionState);
              fetchStockVideos(block.getText().slice(start, end), inputValue);
            } else {
              NotificationInstance.info({
                message: "Please enter a video prompt",
              });
            }
          } else if (type === "VIDEO") {
            if (!selectedVideo) {
              NotificationInstance.info({
                message: "Please select a video",
              });
              return;
            }
            let newContentState = contentState.createEntity(type, "SEGMENTED", {
              text: inputValue,
              video: selectedVideo,
            });
            const newEntityKey = newContentState.getLastCreatedEntityKey();
            newContentState = Modifier.applyEntity(
              newContentState,
              entityType !== "normal" ? entitySelection : selectionState,
              newEntityKey
            );
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              "apply-entity"
            );
            onChange(newEditorState);
            setScriptUpdate();
            setVideos();
            setUserSelectedVideo(false);
            focusEditorAtEnd(newEditorState);
            setInputValue();
          } else {
            setselectedState(selectionState);

            let newContentState = contentState.createEntity(type, "SEGMENTED", {
              text: inputValue,
            });
            const newEntityKey = newContentState.getLastCreatedEntityKey();
            newContentState = Modifier.applyEntity(
              newContentState,
              entityType !== "normal" ? entitySelection : selectionState,
              newEntityKey
            );
            const newEditorState = EditorState.push(
              editorState,
              newContentState,
              "apply-entity"
            );
            setEditorState(newEditorState);
            setScriptUpdate();
            focusEditorAtEnd(newEditorState);
            setInputValue();
          }
        }
      );
    } else {
      const selectedText = contentState
        .getBlockForKey(selectionState.getAnchorKey())
        .getText()
        .slice(selectionState.getStartOffset(), selectionState.getEndOffset());

      setSubtitleText(selectedText);
      if (type.toUpperCase() === "VIDEO" && !videos) {
        setselectedState(selectionState);
        fetchStockVideos(subtitleText, inputValue);
      } else if (type === "VIDEO") {
        let newContentState = contentState.createEntity(type, "SEGMENTED", {
          text: inputValue,
          video: selectedVideo,
        });
        const entityKey = newContentState.getLastCreatedEntityKey();
        newContentState = Modifier.applyEntity(
          newContentState,
          selectedState,
          entityKey
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        onChange(newEditorState);
        setScriptUpdate();
        setVideos();
        setUserSelectedVideo(false);
        focusEditorAtEnd(newEditorState);
        setInputValue();
      } else {
        setselectedState(selectionState);
        let newContentState = contentState.createEntity(type, "SEGMENTED", {
          text: inputValue,
        });
        const entityKey = newContentState.getLastCreatedEntityKey();
        newContentState = Modifier.applyEntity(
          newContentState,
          selectionState,
          entityKey
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        onChange(newEditorState);
        setScriptUpdate();
        focusEditorAtEnd(newEditorState);
        setInputValue();
      }
    }
  };

  const focusEditorAtEnd = (editorState) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const lastBlock = blockMap.last();
    const lastBlockKey = lastBlock.getKey();
    const lastBlockLength = lastBlock.getLength();

    const selection = SelectionState.createEmpty(lastBlockKey).merge({
      anchorOffset: lastBlockLength,
      focusOffset: lastBlockLength,
    });

    const newEditorState = EditorState.forceSelection(editorState, selection);

    setEditorState(newEditorState);
  };
  const removeEntitiesInSubsentence = () => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const startOffset = selectionState.getStartOffset();
    const endOffset = selectionState.getEndOffset();

    const blocks = contentState.getBlockMap();

    // Flag to start processing blocks
    let withinSelection = false;
    let newContentState = contentState;

    // Iterate through all blocks
    blocks.forEach((block, key) => {
      if (key === startKey) withinSelection = true; // Start block
      if (!withinSelection) return; // Skip blocks before startKey

      const blockStartOffset = key === startKey ? startOffset : 0;
      const blockEndOffset = key === endKey ? endOffset : block.getLength();

      // Iterate through offsets in the block's range
      for (let offset = blockStartOffset; offset < blockEndOffset; offset++) {
        const entityKey = block.getEntityAt(offset);

        if (entityKey) {
          // Remove the entity from the specific range
          const rangeToClear = SelectionState.createEmpty(key).merge({
            anchorOffset: offset,
            focusOffset: offset + 1,
          });
          newContentState = Modifier.applyEntity(
            newContentState,
            rangeToClear,
            null
          );
        }
      }

      if (key === endKey) withinSelection = false; // End block
    });

    // Push updated content state to editor state
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "remove-entity"
    );
    onChange(newEditorState);
  };

  const removeEntityAtSelection = () => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const startKey = selectionState.getStartKey();
    const block = contentState.getBlockForKey(startKey);
    const entityKey = block.getEntityAt(selectionState.getStartOffset());
    setVideos();
    setUserSelectedVideo(false);
    setScriptUpdate();
    if (entityKey) {
      // Remove the entity by creating a new content state without it
      const newContentState = Modifier.applyEntity(
        contentState,
        selectionState,
        null
      );
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "remove-entity"
      );
      onChange(newEditorState);
    } else {
      // console.log("No entity present at current selection to remove!");
    }
  };
  const generateOptions = (num) => {
    return Array.from({ length: num }, (_, i) => ({
      label: i + 1,
      value: i + 1,
    }));
  };

  const handleSubmit = (type) => {
    const content = editorState.getCurrentContent();
    const draftData = convertToRaw(content);
    const blocksArray = draftData.blocks || [];
    const entityMap = draftData.entityMap;
    const entitiesArray = [];
    let fullText = ""; // Initialize variable to store the full text

    blocksArray.forEach((block, blockIndex) => {
      const blockText = block.text;
      const blockLength = blockText.length;
      let i = 0;
      let lastOffset = 0;

      while (i < blockLength) {
        const entityRange = block.entityRanges.find(
          (range) => i >= range.offset && i < range.offset + range.length
        );

        if (entityRange) {
          // Add normal text before the entity
          if (lastOffset < entityRange.offset) {
            const normalText = blockText.slice(lastOffset, entityRange.offset);
            entitiesArray.push({
              text: normalText,
              type: "normal",
            });
            fullText += normalText; // Append normal text to fullText
          }

          // Add entity text
          const entity = entityMap[entityRange.key];
          const entityData = entity.data;
          let data;

          if (entity.type.toLowerCase() === "subtitle") {
            data = { subtitleText: entityData.text };
          } else if (entity.type.toLowerCase() === "video") {
            data = {
              promptText: entityData.text,
              stockVideoData: entityData.video,
            };
          } else {
            data = { slideNumber: entityData.text ? entityData.text : 1 };
          }

          const entityText = blockText.slice(
            entityRange.offset,
            entityRange.offset + entityRange.length
          );

          entitiesArray.push({
            text: entityText,
            type: entity.type.toLowerCase(),
            ...data,
          });
          fullText += entityText; // Append entity text to fullText

          // Update the last offset
          lastOffset = entityRange.offset + entityRange.length;
          i += entityRange.length; // Skip to the end of the entity
        } else {
          i++; // Just move to the next character if no entity
        }
      }

      // Add remaining normal text after the last entity
      if (lastOffset < blockLength) {
        const remainingText = blockText.slice(lastOffset);
        entitiesArray.push({
          text: remainingText,
          type: "normal",
        });
        fullText += remainingText; // Append remaining text to fullText
      }

      // Append '\n' to the last item for the block, if not the last block
      if (blockIndex < blocksArray.length - 1 && entitiesArray.length > 0) {
        entitiesArray[entitiesArray.length - 1].text += "\n"; // Append line break to the last entity
        fullText += "\n"; // Append line break to fullText
      }
    });

    // Add index key to each entity
    const indexedEntities = entitiesArray.map((entity, index) => ({
      ...entity,
      order: index,
    }));

    if (fullText) {
      updateScriptParts(indexedEntities, fullText, type);
    } else {
      NotificationInstance.info({
        message: "Please Enter a script",
      });
    }
  };

  const updateScriptParts = (data, script, type) => {
    updateUseCase(lessonId, { finalScript: script })
      .then((res) => {
        setgenerating(type === "save" ? "save" : "submit");
        setcurrentStatus("LESSON_SCRIPT_SUBMITTED");
        setLesson((prev) => ({ ...prev, finalScript: script }));
        updateUsecaseScript(lessonId, {
          scriptParts: data,
          type: "LESSON",
          model: model,
        })
          .then((res) => {
            setLesson((prev) => ({
              ...prev,
              scriptParts: res?.data?.scriptParts,
            }));
            setChunks(res?.data?.scriptParts);
            if (type !== "save") {
              setContentStep((prev) => prev + 1);
            } else {
              NotificationInstance.success({
                message: "Progress saved successfully",
              });
            }
            setgenerating(false);
          })
          .catch((err) => {
            setgenerating(false);
          });
      })
      .catch((err) => {
        setgenerating(false);
      });
  };

  const uploadFile = (formData) => {
    uploadUseCasePPT(lessonId, formData)
      .then((res) => {
        // setloading(false);
        // setVideoId(res?.data?.videoId);
        if (file) {
          removeAllSlideEntities();
        }
        setSlidePreview(res?.data?.slidePreview);
        setPages(res?.data?.totalSlides);

        // setFile(res?.data?.presentationBlobName);
        setLoading(false);
        setIsModalOpen(false);
        NotificationInstance.success({
          message: "File uploaded successfully",
        });
        setSelectedEntityType("SLIDE");
        setScriptUpdate("SLIDE");
      })
      .catch((err) => {
        // setloading(false);
        NotificationInstance.error({ message: "File upload failed" });
      });
  };

  const removeAllSlideEntities = () => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    let newContentState = contentState;

    // Iterate through each block
    blockMap.forEach((block) => {
      const blockKey = block.getKey();
      const blockText = block.getText();

      // Iterate through each character in the block
      for (let i = 0; i < blockText.length; i++) {
        const entityKey = block.getEntityAt(i);

        if (entityKey) {
          const entity = contentState.getEntity(entityKey);

          // If the entity is of type 'SLIDE', remove it by applying null
          if (entity.getType() === "SLIDE") {
            const selectionState = editorState.getSelection().merge({
              anchorKey: blockKey,
              anchorOffset: i,
              focusKey: blockKey,
              focusOffset: i + 1,
            });

            newContentState = Modifier.applyEntity(
              newContentState,
              selectedState,
              null
            );
          }
        }
      }
    });

    // Push the updated content state to the editor
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );

    setEditorState(newEditorState);
  };
  const renderButton = () => {
    return (
      <Flex gap={10} justify="space-between" flex={1}>
        <Flex>
          <Button
            onClick={() => {
              setContentStep((prev) => prev - 1);
            }}
          >
            Back
          </Button>
        </Flex>
        {scriptStages.includes(lesson?.state) && !scriptUpdated ? (
          <Button
            type="primary"
            onClick={() => {
              setContentStep((prev) => prev + 1);
            }}
          >
            Next
          </Button>
        ) : scriptUpdated && scriptUpdateStages.includes(currentStatus) ? (
          <Flex flex={1} justify="flex-end" gap={10}>
            <Button onClick={() => setAudioProfileModal(true)}>
              Select Audio Profile
            </Button>
            <Popconfirm
              overlayStyle={{ width: 400 }}
              title="Lesson Script"
              description="Updating the lesson content will delete all the audios generated previously. Do you want to continue?"
              onConfirm={() => {
                handleSubmit("save");
              }}
            >
              <Button loading={generating === "save"}>
                {generating === "save" ? "Saving Script" : "Save"}
              </Button>
            </Popconfirm>
            <Popconfirm
              overlayStyle={{ width: 400 }}
              title="Lesson Script"
              description="Updating the lesson content will delete all the audios generated previously. Do you want to continue?"
              onConfirm={() => {
                handleSubmit();
              }}
            >
              <Button type="primary" loading={generating === "submit"}>
                {generating === "submit"
                  ? "Generating Audios"
                  : "Confirm Script"}
              </Button>
            </Popconfirm>
          </Flex>
        ) : (
          <Flex flex={1} justify="flex-end" gap={10}>
            <Button onClick={() => setAudioProfileModal(true)}>
              Select Audio Profile
            </Button>
            <Button
              loading={generating === "save"}
              onClick={() => handleSubmit("save")}
            >
              {generating === "save" ? "Saving Script" : "Save"}
            </Button>
            <Popconfirm
              overlayStyle={{ width: 400 }}
              title="Lesson Script"
              description="Confirming the lesson script will start generating audios. Do you want to continue?"
              onConfirm={() => handleSubmit("final")}
            >
              <Button type="primary" loading={generating === "submit"}>
                {generating === "submit"
                  ? "Generating Audios"
                  : "Confirm Script"}
              </Button>
            </Popconfirm>
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <Row className="course-detail">
      <Col
        span={scriptUpdate && scriptUpdate !== "normal" ? 16 : 24}
        className="lesson-detail-container"
      >
        <Row>
          <Col span={24}>
            <Flex className="course-detail-header" justify="space-between">
              <span className="course-header-title">Lesson Script</span>
              {isModalOpen && (
                <UploadVideoPresentation
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  handleCancel={() => setIsModalOpen(false)}
                  handleOk={uploadFile}
                  setFile={setFile}
                  file={file}
                  setloading={setLoading}
                  loading={loading}
                />
              )}
              {audioProfileModal && (
                <AudioProfileModal
                  visible={audioProfileModal}
                  onClose={() => setAudioProfileModal(false)}
                  onSelect={handleAudioProfileChange}
                  audioProfiles={audioProfiles}
                />
              )}
              {!videoStages.includes(currentStatus) &&
                (isEntityAtSelection ? (
                  <Popconfirm
                    overlayStyle={{ width: 400 }}
                    title="Lesson Script"
                    description="Removing entities will delete all keywords, slides, and videos added. Do you want to continue?"
                    onConfirm={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeEntitiesInSubsentence(); // Call your function to remove entities
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="edit-title">Remove</Button>
                  </Popconfirm>
                ) : (
                  <Flex gap={20} ref={buttonsRef} align="center">
                    {items.map((item) => (
                      <Tooltip
                        title={`${
                          selectedTexts
                            ? ""
                            : "Please select at least one sentence"
                        }`}
                      >
                        <Button
                          className="edit-title"
                          disabled={!selectedTexts}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleEntityTypeChange(item.type);
                          }}
                          icon={item.icon}
                          color="white"
                        >
                          Add {capitalizeInput(item.title)}
                        </Button>
                      </Tooltip>
                    ))}
                    <Select
                      value={model}
                      style={{ width: 140 }}
                      placeholder="Avatar Model"
                      onChange={(val) => {
                        setModel(val);
                        setScriptUpdated(true);
                      }}
                      options={[
                        { label: "Instant", value: "leapRoad" },
                        { label: "Studio", value: "heygen" },
                      ]}
                    />
                  </Flex>
                ))}
            </Flex>
          </Col>
        </Row>
        <Row vertical className="course-content" style={{ flex: 1 }}>
          <Col span={24}>
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={onChange}
              placeholder="Enter the script"
              handleBeforeInput={handleBeforeInput}
              handleKeyCommand={handleBeforeInput}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Flex flex={1} className="course-detail-footer" justify="flex-end">
              {renderButton("lessonScript")}
            </Flex>
          </Col>
        </Row>
      </Col>
      {scriptUpdate && scriptUpdate !== "normal" && (
        <Col
          className="lesson-script-detail"
          style={{ flex: 1 }}
          span={8}
          ref={rightColRef}
        >
          <Row
            align="center"
            className="lesson-detail-container"
            style={{ height: "100%" }}
          >
            <Col className="usecase-content-wrapper" span={24}>
              <Row>
                <Col className="usecase-header" span={24}>
                  <Flex justify="space-between" gap={10}>
                    <span className="usecase-header-title">
                      Add{" "}
                      {capitalizeInput(
                        scriptUpdate === "SUBTITLE" ? "KEYWORD" : scriptUpdate
                      )}
                    </span>
                    <Flex gap={20}>
                      {videos && (
                        <Button
                          onClick={() => {
                            setVideos(null);
                            setUserSelectedVideo(false);
                          }}
                        >
                          Clear
                        </Button>
                      )}
                      {pages !== null &&
                        pages !== undefined &&
                        scriptUpdate === "SLIDE" && (
                          <Button
                            onClick={() => {
                              setIsModalOpen(true);
                            }}
                          >
                            Change Slide
                          </Button>
                        )}
                      <Button
                        onClick={() => {
                          setScriptUpdate();
                          // clearHighlight();
                        }}
                      >
                        Close
                      </Button>
                    </Flex>
                  </Flex>
                </Col>
              </Row>
              <Row
                style={{ flex: 1 }}
                align="center"
                className=" course-content"
              >
                <Col span={24}>{renderScriptContent(scriptUpdate)}</Col>
              </Row>
              {!videoStages.includes(currentStatus) && (
                <Row>
                  <Col className="usecase-footer" span={24}>
                    <Flex flex={1} gap={20} justify="flex-end">
                      {scriptUpdate?.toLowerCase() === "video" &&
                      !videos &&
                      !userSelectedVideo ? (
                        <Button
                          loading={videoLoading}
                          htmlType="submit"
                          onClick={() => setEntityAtSelection(scriptUpdate)}
                        >
                          Get Stock Videos
                        </Button>
                      ) : userSelectedVideo &&
                        scriptUpdate?.toLowerCase() === "video" ? (
                        <>
                          {inputValue && showRemove && (
                            <Button
                              htmlType="submit"
                              onClick={removeEntityAtSelection}
                            >
                              Remove {capitalizeInput(scriptUpdate)}
                            </Button>
                          )}
                          <Button
                            onClick={() => {
                              setVideos();
                              setUserSelectedVideo(false);
                              // setEntityAtSelection(scriptUpdate);
                              setInputValue();
                            }}
                          >
                            Change Video
                          </Button>
                        </>
                      ) : (
                        <>
                          {showRemove && (
                            <Button
                              htmlType="submit"
                              onClick={removeEntityAtSelection}
                            >
                              Remove{" "}
                              {capitalizeInput(
                                scriptUpdate === "SUBTITLE"
                                  ? "KEYWORD"
                                  : scriptUpdate
                              )}
                            </Button>
                          )}
                          <Button
                            htmlType="submit"
                            onClick={() => setEntityAtSelection(scriptUpdate)}
                          >
                            Save{" "}
                            {capitalizeInput(
                              scriptUpdate === "SUBTITLE"
                                ? "KEYWORD"
                                : scriptUpdate
                            )}
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default LessonScriptUpdation;
