import { Button, Flex, Progress } from "antd";
import React, { useEffect, useState } from "react";
import {
  downloadLessonVideo,
  getUseCaseVideo,
} from "../../../api/adminService";
import "./videoGeneration.css";

const VideoGeneration = ({
  usecaseData,
  status,
  videoStatus,
  useCaseId,
  selectedAvatarProfile,
  videoId,
}) => {
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (status === "AUDIO_GENERATION_COMPLETED") {
      getUseCaseVideo(useCaseId, selectedAvatarProfile)
        .then((res) => {})
        .catch((err) => {});
    }
  }, [status]);

  const getProgressPercentage = (status) => {
    if (status && status?.toLowerCase() === "processing") {
      return "0";
    }
    if (status && status?.toLowerCase() === "uploading") {
      return "98";
    }
    if (status && status?.includes("out of")) {
      const [currentStep, totalSteps] = status
        .split(" out of ")
        .map((num) => parseInt(num, 10));
      if (!isNaN(currentStep) && !isNaN(totalSteps) && totalSteps > 0) {
        const percentage = (currentStep / totalSteps) * 100;
        return `${percentage.toFixed(0)}`;
      }
    }
    if (status && status?.toLowerCase() === "completed") {
      return "100";
    }

    return "0";
  };

  const handleDownload = async () => {
    setDownloading(true);
    downloadLessonVideo(videoId)
      .then((res) => {
        const videoUrl = res?.data?.url;
        if (videoUrl) {
          setDownloading(false);
          const link = document.createElement("a");
          link.href = videoUrl;
          link.setAttribute("download", "video.mp4");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.log(err);
        setDownloading(false);
      });
  };

  return (
    usecaseData && (
      <Flex
        vertical
        gap={20}
        align="center"
        justify="center"
        flex={1}
        className="video-output"
      >
        {status === "VIDEO_GENERATION_COMPLETED" ? (
          <Flex
            vertical
            className="video-output-container"
            gap={20}
            align="center"
            justify="center"
            flex={1}
          >
            <Flex
              flex={1}
              className="preview-container"
              style={{ width: "100%" }}
            >
              <iframe
                title="video-player"
                src={usecaseData?.videoData?.url}
                loading="lazy"
                style={{
                  border: "none",
                  // position: "absolute",
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: "100%",
                }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen
                onError={(e) => console.error("Error loading video:", e)}
              ></iframe>
            </Flex>
            <Button onClick={handleDownload} loading={downloading}>
              {downloading ? "Downloading Video" : "Download Video"}
            </Button>
          </Flex>
        ) : (
          <Flex
            //   flex={1}
            className="course-content"
            align="center"
            vertical
            gap={20}
            style={{
              width: "100%",
            }}
          >
            <Progress
              type="circle"
              percent={getProgressPercentage(videoStatus)}
            />
            <h2>Video Generation In Progress</h2>
          </Flex>
        )}
      </Flex>
    )
  );
};

export default VideoGeneration;
