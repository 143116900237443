// ORGANIZATION_CREATOR can share resource within his organization to other members in his organization
// ORGANIZATION_ADMIN can share resource public or can share it with the members in his organization

import {
  Button,
  Checkbox,
  Flex,
  Form,
  Modal,
  Popconfirm,
  Select,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import {
  getOrganizations,
  getShareResourceById,
  updateSharedResource,
} from "../../api/adminService";
import "./modal.css";
import {
  GlobalOutlined,
  InfoCircleOutlined,
  LockOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import NotificationInstance from "../../services/notificationServices";

const shareOptions = [
  {
    icon: <GlobalOutlined />,
    label: "Anyone",
    value: "anyone",
    description: "Share with anyone, making it accessible to everyone.",
  },
  {
    icon: <LockOutlined />,
    label: "My Organization",
    value: "organization",
    description: "Share with people within your organization.",
  },
  {
    icon: <UsergroupAddOutlined />,
    label: "Selected Organizations",
    value: "selectedOrganizations",
    description: "Share only with specific organizations of your choice.",
  },
];

const ShareOptionsModal = ({
  isModalOpen,
  handleCancel,
  type,
  selectedItemForSharing,
  userDetails,
  setRefresh,
  refresh,
}) => {
  const [organizationData, setOrganizationData] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sharedResource, setSharedResource] = useState(false);
  const [total, setTotal] = useState([]);
  const [searchKey, setSearchKey] = useState([]);
  const [loading, setLoading] = useState();
  const [sharing, setSharing] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [unShareResource, setUnShareResource] = useState(false);

  const [form] = Form.useForm();

  const userRole =
    userDetails?.role || userDetails?.organizationInfo[0]?.organizationRole;

  const rolePermissions = {
    ORGANIZATION_CREATOR: "ORGANIZATION_CREATOR",
    ORGANIZATION_ADMIN: "ORGANIZATION_ADMIN",
    SUPER_ADMIN: "SUPER_ADMIN",
  };

  const filteredShareOptions = shareOptions.map((option) => ({
    ...option,
    disabled: !(
      userRole === rolePermissions.ORGANIZATION_ADMIN ||
      userRole === rolePermissions.SUPER_ADMIN ||
      option.value === "organization"
    ),
  }));

  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };

  const handleCheckboxChange = (checkedValues) => {
    if (checkedValues.length > 0) {
      setSharedResource(true);
    }
    if (
      userRole === rolePermissions.ORGANIZATION_CREATOR &&
      checkedValues.includes("anyone")
    ) {
      return;
    }
    if (!checkedValues.includes("selectedOrganizations")) {
      form.setFieldsValue({
        selectedOrganizations: [],
      });
      setSelectedOrganizations([]);
    }
    if (checkedValues.includes("anyone")) {
      setSelectedOptions(["anyone"]);
      form.setFieldsValue({
        shareTo: ["anyone"],
        selectedOrganizations: [],
      });
    } else {
      setSelectedOptions(checkedValues);
      form.setFieldsValue({
        shareTo: checkedValues,
      });
    }
  };

  useEffect(() => {
    if (userRole && userRole !== rolePermissions.ORGANIZATION_CREATOR) {
      getOrganizationData();
    }
  }, [searchKey, currentPage, userRole]);

  const getOrganizationData = () => {
    setLoading(true);
    getOrganizations(searchKey, "", 20, currentPage)
      .then((res) => {
        const options = res.data.map((org) => ({
          label: org.name,
          value: org._id,
        }));

        setOrganizationData((prev) =>
          currentPage === 1 ? options : [...prev, ...options]
        );

        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleOrganizationChange = (values) => {
    setSelectedOrganizations(values);
  };

  const handleConfirm = () => {
    form
      .validateFields()
      .then(() => {
        setSharing("sharing");
        let updatedData;
        if (
          Array.isArray(selectedOrganizations) &&
          selectedOrganizations.every((item) => typeof item === "string")
        ) {
          updatedData = {
            resourceId: selectedItemForSharing?._id,
            isPublic: selectedOptions.includes("anyone"),
            shareWithinOrganization: selectedOptions.includes("organization"),
            sharedTo: selectedOptions.includes("selectedOrganizations")
              ? selectedOrganizations
              : [],
            type: type.toUpperCase(),
            ...(selectedItemForSharing?.type && {
              useCaseType: selectedItemForSharing.type,
            }),
          };
        } else if (
          Array.isArray(selectedOrganizations) &&
          selectedOrganizations.every(
            (item) =>
              typeof item === "object" &&
              item.hasOwnProperty("label") &&
              item.hasOwnProperty("value")
          )
        ) {
          updatedData = {
            resourceId: selectedItemForSharing?._id,
            isPublic: selectedOptions.includes("anyone"),
            shareWithinOrganization: selectedOptions.includes("organization"),
            sharedTo: selectedOptions.includes("selectedOrganizations")
              ? selectedOrganizations.map((org) => org.value)
              : [],
            type: type.toUpperCase(),
            ...(selectedItemForSharing?.type && {
              useCaseType: selectedItemForSharing.type,
            }),
          };
        }
        if (
          selectedOptions.includes("selectedOrganizations") &&
          updatedData.sharedTo.length === 0
        ) {
          setSharing(false);
          return;
        }
        handleUpdateResource(
          updatedData,
          selectedItemForSharing?._id,
          "shared"
        );
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
      });
  };

  const handleUnshare = () => {
    const updatedData = {
      resourceId: selectedItemForSharing?._id,
      isPublic: false,
      shareWithinOrganization: false,
      sharedTo: [],
      type: type.toUpperCase(),
      ...(selectedItemForSharing?.type && {
        useCaseType: selectedItemForSharing.type,
      }),
    };
    setSharing("unsharing");

    handleUpdateResource(updatedData, selectedItemForSharing?._id, "unshared");
  };

  const handleUpdateResource = (updatedData, id, shareType) => {
    updateSharedResource(updatedData, id)
      .then((result) => {
        NotificationInstance.success({
          message: `${type} ${shareType} successfully`,
        });
        setRefresh(!refresh);
        setSharing(false);
        onCancel();
      })
      .catch((err) => {
        setSharing(false);
        console.log(err);
      });
  };

  const onScroll = (e) => {
    const { target } = e;

    const isAtBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >= target.scrollHeight;
    // console.log(isAtBottom, organizationData.length, total);

    if (isAtBottom && organizationData?.length < total) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setSelectedOrganizations();
    handleCancel();
    setSelectedOptions([]);
    setUnShareResource(false);
    setSharedResource(false);
  };

  useEffect(() => {
    if (selectedItemForSharing) {
      setLoadingDetails(true);
      getShareResourceById(selectedItemForSharing?._id, type.toUpperCase())
        .then((res) => {
          const sharedTo = [];
          if (res?.data?.shareWithAll) {
            sharedTo.push("anyone");
          }
          if (res?.data?.shareWithinOrganization) {
            sharedTo.push("organization");
          }
          if (res?.data?.sharedTo?.length > 0) {
            sharedTo.push("selectedOrganizations");
          }
          const selectedOrg =
            res?.data?.sharedTo?.length > 0
              ? res?.data?.sharedTo.map((org) => ({
                  label: org.name,
                  value: org._id,
                }))
              : [];
          setSelectedOptions(sharedTo);
          if (sharedTo.length > 0) {
            setSharedResource(true);
            setUnShareResource(true);
          } else if (sharedTo.length === 0) {
            setSharedResource(false);
          }
          setSelectedOrganizations(selectedOrg);
          form.setFieldsValue({
            shareTo: sharedTo,
            selectedOrganizations: selectedOrg,
          });
          setLoadingDetails(false);
        })
        .catch((err) => {
          setLoadingDetails(false);
        });
    }
  }, [selectedItemForSharing]);

  return (
    <Modal
      centered
      width={500}
      title={
        <Flex vertical gap={5}>
          <span>Share {type}</span>
          <span className="shared-item">
            <i>{selectedItemForSharing?.title}</i>
          </span>
        </Flex>
      }
      open={isModalOpen}
      onCancel={onCancel}
      footer={[
        sharing !== "unsharing" && (
          <Popconfirm
            title="Are you sure you want to share this item?"
            onConfirm={handleConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              loading={sharing === "sharing"}
              type="primary"
              disabled={!sharedResource || selectedOptions?.length === 0}
            >
              Share
            </Button>
          </Popconfirm>
        ),
        unShareResource && (
          <Popconfirm
            title="Are you sure you want to unshare this item?"
            onConfirm={handleUnshare}
            okText="Yes"
            cancelText="No"
          >
            <Button loading={sharing === "unsharing"}>Unshare</Button>
          </Popconfirm>
        ),
        <Button key="cancel" onClick={onCancel} type="text">
          Cancel
        </Button>,
      ]}
    >
      {loadingDetails ? (
        <div className="thumbnail-upload">
          <div className="video-loader">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            shareTo: [],
            selectedOrganizations: [],
          }}
        >
          <Form.Item
            name="shareTo"
            label=""
            // rules={[
            //   {
            //     validator: (_, value) => {
            //       if (
            //         value?.length > 0 ||
            //         selectedItemForSharing?.sharedTo?.length > 0 ||
            //         selectedItemForSharing?.shareWithinOrganization ||
            //         selectedItemForSharing?.isPublic
            //       ) {
            //         return Promise.resolve();
            //       }
            //       return Promise.reject("Please select a sharing option");
            //     },
            //   },
            // ]}
          >
            <Checkbox.Group
              onChange={handleCheckboxChange}
              style={{ width: "100%" }}
            >
              <Flex vertical gap={20} flex={1}>
                {filteredShareOptions.map((option) => (
                  <Flex justify="space-between" key={option.value}>
                    <Flex className="share-resource" gap={10} align="center">
                      {option.icon}
                      <span className="share-to">
                        {option.label}{" "}
                        <Tooltip title={option.description}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    </Flex>
                    <Checkbox
                      disabled={
                        (!selectedOptions.includes(option.value) &&
                          selectedOptions.includes("anyone")) ||
                        option.disabled
                      }
                      value={option.value}
                    />
                  </Flex>
                ))}
              </Flex>
            </Checkbox.Group>
          </Form.Item>

          {selectedOptions.includes("selectedOrganizations") && (
            <Form.Item
              required={false}
              name="selectedOrganizations"
              label="Select Organizations"
              validateStatus={
                selectedOptions.includes("selectedOrganizations") &&
                selectedOrganizations.length === 0
                  ? "error"
                  : ""
              }
              help={
                selectedOptions.includes("selectedOrganizations") &&
                selectedOrganizations.length === 0
                  ? "Please select at least one organization."
                  : null
              }
            >
              <Select
                disabled={userRole === rolePermissions.ORGANIZATION_CREATOR}
                className="organization-list-select"
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                value={selectedOrganizations}
                filterOption={false}
                placeholder="Select Organization"
                options={organizationData}
                onChange={handleOrganizationChange}
                onPopupScroll={onScroll}
                onSearch={(value) => debouncedSearch(value)}
                loading={loading}
              />
            </Form.Item>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default ShareOptionsModal;
