import React, { useEffect, useState } from "react";
import "./organizations.css";
import { Button, ConfigProvider, Input, Select } from "antd";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Logo } from "../../assets/images/searchPrefix.svg";
import { ReactComponent as Check } from "../../assets/images/checkMark.svg";
import { useOutletContext } from "react-router-dom";
import {
  createNewOrgranisation,
  getOrganizations,
} from "../../api/adminService";
import { timeCreatedOn } from "../../utils/format";
import { StatusTag } from "../../components/tags/tags";
import { PRIMARY_COLOR } from "../../utils/colors";
import CustomPagination from "../../components/customPagination/customPagination";
import OrganizationTable from "../../components/organizationList/organizationsList";
import NotificationInstance from "../../services/notificationServices";
import AddOrganizationModal from "../../components/modals/addOrganizationModal";

const sortOptions = [
  {
    value: "name.ASC",
    label: "Name (A to Z)",
  },
  {
    value: "name.DESC",
    label: "Name (Z to A)",
  },
  {
    value: "status.ASC",
    label: "Status (Active to Inactive)",
  },
  {
    value: "status.DESC",
    label: "Status (Inactive to Active)",
  },
  {
    value: "createdAt.ASC",
    label: "CreatedAt (old to new)",
  },
  {
    value: "createdAt.DESC",
    label: "CreatedAt (new to old)",
  },
];

const Organizations = () => {
  const [organizationName, setOrganizationName] = useState({
    value: "",
    error: false,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organization, setOrganization] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [status, setStatus] = useState("");
  const [sortField, setSortField] = useState();
  const [loading, setLoading] = useState(false);
  const [progress, setInprogress] = useState(false);
  const [sortOrder, setsortOrder] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [organizationCreated, setOrganizationCreated] = useState(false);
  const [, , setDisplayName] = useOutletContext();
  const [detailedOrganizationData, setdetailedOrganizationData] =
    useState(null);

  const ORGANIZATION_STATUSES = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  };

  useEffect(() => {
    setDisplayName(["Organizations"]);
  }, []);

  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };
  const onSort = (val) => {
    setCurrentPage(1);
    const [field, order] = val.split(".");
    setSortField(field);
    setsortOrder(order);
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addNewOrganization = (organizationData) => {
    createNewOrgranisation(organizationData)
      .then((res) => {
        setInprogress(false);
        setIsModalOpen(false);
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully added new organization.",
        });

        const newOrganization = {
          key: res.data._id,
          name: res.data.name,
          status: <StatusTag status={res.data.status} />,
          createdBy: res?.data?.createdByUser,
          createdAt: timeCreatedOn(res.data.createdAt),
        };

        setOrganization((prevOrganizations) => [
          newOrganization,
          ...prevOrganizations,
        ]);
        setOrganizationCreated(true);
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        NotificationInstance.error({
          message: "Organization creation failed",
          description: error.message,
        });
        setInprogress(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getOrganizations(
      searchKey,
      status,
      pageSize,
      currentPage,
      sortField,
      sortOrder
    )
      .then((res) => {
        setdetailedOrganizationData(res);
        const responseData = res?.data.map((item) => ({
          key: item._id,
          name: item.name,
          status: <StatusTag status={item.status} />,
          createdBy: item.createdByUser,
          createdAt: timeCreatedOn(item.createdAt),
        }));
        setOrganization(responseData);
        setTotal(res?.info?.totalCount ?? 0);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [searchKey, status, sortField, sortOrder, pageSize, currentPage, refresh]);

  return (
    <div>
      <div className="journey-header">
        <div className="journey-title">
          <h1>All Organizations</h1>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
            },
            components: {
              Select: {
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Radio: {
                colorPrimary: PRIMARY_COLOR,
              },
              Checkbox: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
              },
              Drawer: {
                footerPaddingBlock: 30,
              },
              Badge: {
                dotSize: 10,
              },
              Menu: {
                colorPrimary: PRIMARY_COLOR,
              },
            },
          }}
        >
          <div className="journey-action">
            <div className="sort-action">
              <span className="sort-prefix">Sort : </span>
              <Select
                placeholder="Select a sort"
                optionFilterProp="children"
                onSelect={onSort}
                variant="borderless"
                popupMatchSelectWidth={true}
                defaultValue={"createdAt.DESC"}
                popupClassName="sort-options"
                title="label"
                options={sortOptions}
                menuItemSelectedIcon={<Check />}
                listHeight={270}
              />
            </div>
            <Input
              width={200}
              placeholder="Search"
              prefix={<Search />}
              suffix={<Logo />}
              className="search-input"
              onChange={onSearch}
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="create-button"
            >
              Add New Organization
            </Button>
            <div>
              <AddOrganizationModal
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                handleOk={addNewOrganization}
                organization={organizationName}
                setOrganization={setOrganizationName}
                setInprogress={setInprogress}
                progress={progress}
                organizationCreated={organizationCreated}
              />
            </div>
          </div>
        </ConfigProvider>
      </div>
      <div className="journey-wrapper">
        <OrganizationTable
          data={organization}
          loading={loading}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          detailedOrganizationData={detailedOrganizationData}
          setRefresh={setRefresh}
          refresh={refresh}
        />
        {total > pageSize && (
          <CustomPagination
            pageSize={pageSize}
            currentPage={currentPage}
            total={total}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default Organizations;
