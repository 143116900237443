import { Button, Flex, Form, Input, Modal, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { addNewUser } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";

const AddUserModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  setIsModalOpen,
  organizationId,
}) => {
  const [category, setCategory] = useState({
    value: null,
    error: false,
  });
  const [type, setType] = useState({
    value: "SUPER_ADMIN",
    error: false,
  });
  const [orgRole, setOrgRole] = useState({
    value: "ADMIN",
    error: false,
  });
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        setDisable(true);
        const data = {
          ...val,
          organizationRole: orgRole.value,
        };
        addNewUser(organizationId, data)
          .then((res) => {
            setDisable(false);
            form.resetFields();
            setOrgRole({ value: "ADMIN", error: false });
            setIsModalOpen(!isModalOpen);
            handleOk();
            NotificationInstance.success({
              message: "Successful",
              description: "Invitation send to the user!",
            });
          })
          .catch((err) => {
            console.log(err);
            setDisable(false);
            if (
              err &&
              err.response.data.message ===
                "User already exists in Organization"
            ) {
              NotificationInstance.error({
                message: "User creation failed",
                description: "This user already exists in the organization",
              });
            } else {
              console.log(err);
              NotificationInstance.error({
                message: "User creation failed",
                description: "Please try again.",
              });
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onTypeChange = (event) => {
    setType({
      value: event.target.value,
      error: event.target.value ? false : true,
    });
  };

  const onModalClose = () => {
    setType({ value: "SUPER_ADMIN", error: false });
    setOrgRole({ value: "ADMIN", error: false });
    setCategory({
      value: null,
      error: false,
    });
    form.resetFields();
    handleCancel();
  };

  const organizationRole = [
    { label: "Admin", value: "ORGANIZATION_ADMIN" },
    { label: "Creator", value: "ORGANIZATION_CREATOR" },
    { label: "User", value: "ORGANIZATION_USER" },
  ];

  return (
    <Modal
      style={{ top: "20px", marginBottom: "40px" }}
      centered
      width={500}
      title="Add New User"
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={disable}
            loading={disable}
          >
            Add User
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="add-user"
        >
          <Form.Item
            name="email"
            label="Email"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter email",
                validateTrigger: "onSubmit",
              },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input
              autoComplete="off"
              aria-autocomplete="off"
              placeholder="Enter email here"
            />
          </Form.Item>

          <Form.Item
            required={false}
            name="orgRole"
            label="Organization Role"
            rules={[
              {
                required: true,
                message: "Please select an organization role",
              },
            ]}
          >
            <Flex vertical gap={0}>
              <Radio.Group
                onChange={onTypeChange}
                value={orgRole.value}
                className="organization-roles"
              >
                {organizationRole.map((role) => (
                  <div
                    key={role.value}
                    className={`journey-type ${
                      orgRole.value === role.value && "active"
                    }`}
                    onClick={() => {
                      setOrgRole({
                        value: role.value,
                        error: false,
                      });
                      form.setFieldValue("orgRole", role.value);
                    }}
                  >
                    <Radio value={role.value}>{role.label}</Radio>
                  </div>
                ))}
              </Radio.Group>
            </Flex>
          </Form.Item>
          <Form.Item
            name="heyGenAvatarId"
            label="Heygen Avatar Id"
            required={false}
          >
            <Input
              autoComplete="off"
              aria-autocomplete="off"
              placeholder="Enter heygen id here"
            />
          </Form.Item>

          <Form.Item
            name="elevenLabsVoiceId"
            label="ElevenLabs Voice Id"
            required={false}
          >
            <Input
              autoComplete="off"
              aria-autocomplete="off"
              placeholder="Enter elevenlabs id here"
            />
          </Form.Item>
          <Form.Item
            name="elevenLabsApiKey"
            label="ElevenLabs Api Key"
            required={false}
          >
            <Input
              autoComplete="off"
              aria-autocomplete="off"
              placeholder="Enter elevenlabs api key here"
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddUserModal;
