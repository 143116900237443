import "./App.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import DashboardLayout from "./components/layout/dashboardLayout";
import Login from "./pages/login/login";
import Journey from "./pages/journey/journey";
import { useContext, useEffect, useState } from "react";
import AuthContext from "./services/storageService";
import AllJourneys from "./pages/allJourneys/allJourneys";
import AllVideos from "./pages/allVideos/allVideos";
import CategoryJourneys from "./pages/categoryJourneys/categoryJourneys";
import Users from "./pages/users/users";
import Categories from "./pages/categories/categories";
import VideoPreview from "./pages/videoPreview/videoPreview";
import UpdatePassword from "./pages/login/forgotPassword";
import ResetPassword from "./pages/login/resetPassword";
import DIYDemo from "./pages/DIYDemo/DIYDemo";
import DIYDemoVideo from "./pages/DIYDemoVideo/DIYDemoVideo";
import PlaceHolder from "./pages/placeHolder/placeHolder";
import CourseVideoList from "./pages/courseList/courseList";
import CourseGeneration from "./pages/courseGeneration/courseGeneration";
import VideoUseCase from "./pages/videoUseCase/videoUseCase";
import Organizations from "./pages/organizations/organizations";
import { getUserDetails } from "./api/profileServices";
import OrganizationDetails from "./pages/organizationDetails/organizationDetails";
import VerificationPage from "./pages/verifyUser/verifyUser";
import SignupPage from "./pages/signup/signup";
import AllProjects from "./pages/allProjects/allProjects";
import ProjectDetails from "./pages/projectDetails/projectDetails";
import MyAvatars from "./pages/avatars/avatars";
import AvatarCollection from "./pages/avatars/avatarCollection";

const rolePermissions = {
  SUPER_ADMIN: [
    "users",
    "organizations",
    "journeys",
    "categories",
    "journeyDetails",
    "categoryDetails",
  ],
  ORGANIZATION_ADMIN: [
    "users",
    "journeys",
    "categories",
    "journeyDetails",
    "categoryDetails",
  ],
  ORGANIZATION_CREATOR: [
    "journeys",
    "categories",
    "journeyDetails",
    "categoryDetails",
  ],
  ORGANIZATION_USER: [],
};

function App() {
  const [userDetails, setuserDetails] = useState();
  const auth = useContext(AuthContext);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1200);

  useEffect(() => {
    if (!auth.token) return;
    getUserDetails()
      .then((res) => {
        setuserDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [auth.token]);

  const checkRolePermission = (role, route) => {
    return rolePermissions[role]?.includes(route);
  };

  const ProtectedRoute = ({ element, route }) => {
    if (!userDetails) {
      return <div>Loading...</div>;
    }
    const role =
      userDetails?.role || userDetails?.organizationInfo[0]?.organizationRole;

    if (!checkRolePermission(role, route)) {
      return route === "journeys" ? (
        <Navigate to="/videos" />
      ) : (
        <Navigate to="/" />
      );
    }
    return element;
  };

  const publicRoutes = createBrowserRouter([
    {
      path: "/auth/verify-user",
      element: <VerificationPage />,
    },
    {
      path: "/auth/signup",
      element: <SignupPage />,
    },
    {
      path: "/video/:videoId",
      element: <VideoPreview />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forgot-password",
      element: <UpdatePassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/demo/:language",
      element: <DIYDemo />,
    },
    {
      path: "/demo/:language/:demoId",
      element: <DIYDemoVideo />,
    },
    {
      path: "/demo/video/:videoId",
      element: <VideoPreview />,
    },
    {
      path: "*",
      element: <Navigate to="/login" />,
    },
  ]);

  const privateRoutes = createBrowserRouter([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/",
          element: (
            <ProtectedRoute element={<AllJourneys />} route="journeys" />
          ),
        },
        {
          path: "/users",
          element: <ProtectedRoute element={<Users />} route="users" />,
        },
        {
          path: "/categories",
          element: (
            <ProtectedRoute element={<Categories />} route="categories" />
          ),
        },
        {
          path: "/organizations",
          element: (
            <ProtectedRoute element={<Organizations />} route="organizations" />
          ),
        },
        // {
        //   path: "/organizations/:organizationId",
        //   element: (
        //     <ProtectedRoute
        //       element={<OrganizationDetails />}
        //       route="organizationDetails"
        //     />
        //   ),
        // },
        {
          path: "/:categoryId",
          element: (
            <ProtectedRoute
              element={<CategoryJourneys />}
              route="categoryDetails"
            />
          ),
        },
        {
          path: "/courses",
          element: <CourseVideoList />,
        },
        {
          path: "/videos",
          element: <AllVideos />,
        },
        {
          path: "/videos/:type/:useCaseId",
          element: <VideoUseCase />,
        },
        {
          path: "/:categoryId/:operation/:journeyId",
          element: (
            <ProtectedRoute element={<Journey />} route="journeyDetails" />
          ),
        },

        {
          path: "/projects",
          element: <AllProjects />,
        },
        {
          path: "/projects/:projectId",
          element: <ProjectDetails />,
        },
        {
          path: "/my-avatars",
          element: <AvatarCollection />,
        },
      ],
    },
    {
      path: "/demo/:language",
      element: <DIYDemo />,
    },
    {
      path: "/demo/:language/:demoId",
      element: <DIYDemoVideo />,
    },
    {
      path: "/demo/video/:videoId",
      element: <VideoPreview />,
    },

    {
      path: "/courses/create",
      element: <CourseGeneration />,
    },
    {
      path: "/courses/:courseIds",
      element: <CourseGeneration />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return auth.token ? (
    isScreenSmall && !window.location.pathname.includes("/demo") ? (
      <PlaceHolder />
    ) : (
      <RouterProvider router={privateRoutes} />
    )
  ) : (
    <RouterProvider router={publicRoutes} />
  );
}

export default App;
