import { Avatar, Button, Flex, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import {
  createVideoUseCase,
  getCourseBriefingQuestions,
} from "../../api/adminService";
import {
  createNewProject,
  createProjectCourse,
  getAllProjects,
} from "../../api/projectServices";
import NotificationInstance from "../../services/notificationServices";
import { getContacts } from "../../api/hubSpotService";
import { useNavigate } from "react-router-dom";

const customOption = (option) => {
  return (
    <Flex gap={10} align="center">
      <Avatar
        shape={"circle"}
        src={`https://ui-avatars.com/api/?name=${option.data.label}&background=101A24&color=fff`}
        size={30}
      />
      <Flex vertical>
        <b>{option.data.label}</b>
        <span role="img" aria-label={option.data.label}>
          {option.data.email}
        </span>
      </Flex>
    </Flex>
  );
};

const CreateCourseModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  itemType = "Course",
  project = false,
}) => {
  const [leads, setLeads] = useState([]);
  const [selectedProject, setSelectedProject] = useState(project);
  const [total, setTotal] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setinitial] = useState(true);
  const [type, setType] = useState();
  const [disable, setDisable] = useState(false);
  const [questions, setQuestions] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        let params;
        if (itemType === "Course") {
          const { title, projectId, ...rest } = val;
          if (!projectId && !project) {
            navigate("/courses/create", { state: { title } });
            return;
          }
          const answers = Object.keys(rest).map((key) => ({
            questionId: key,
            answer: rest[key],
          }));
          const data = { title, answers };
          params = {
            projectId: project || projectId, // use `project` if available, otherwise `projectId`
            data,
          };
        } else {
          const { title } = val;
          const data = { title };
          params = data;
        }
        console.log(params);

        setDisable(true);
        const createAction =
          itemType === "Course" ? createProjectCourse : createNewProject;
        createAction(params)
          .then((res) => {
            setDisable(false);
            form.resetFields();
            if (type === "Course") {
              handleOk(res?.data?.id, type);
            } else {
              handleOk(res?.data?.id);
            }
            NotificationInstance.success({
              message: "Successful",
              description: `Successfully created new ${
                itemType === "Project" ? "project" : "course"
              }`,
            });
          })
          .catch((err) => {
            console.log(err);
            setDisable(false);
            NotificationInstance.error({
              message: `${
                itemType === "Project" ? "Project" : "Course"
              } creation failed`,
              description: "Please try again.",
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onTypeChange = (value) => {
    setType(value);
  };

  const onModalClose = () => {
    setType();
    if (!project) {
      setSelectedProject();
    }
    form.resetFields();
    handleCancel();
  };

  const fetchProjects = () => {
    getAllProjects("", "", "", 20, currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.title,
          value: item._id,
        }));
        if (initial) {
          setLeads(responseData);
        } else {
          setLeads((prevCategories) => [...prevCategories, ...responseData]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchProjects();
  }, [currentPage]);

  useEffect(() => {
    if (itemType === "Course" || project) {
      getCourseBriefingQuestions(true)
        .then((res) => {
          setQuestions(res.data);
          if (project) {
            setSelectedProject(project);
          }
        })
        .catch((err) => {});
    }
  }, [itemType, project]);

  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && leads?.length < total) {
      setcurrentPage((prev) => prev + 1);
    }
  };

  return (
    <Modal
      centered
      width={600}
      title={`Create New ${itemType}`}
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={disable}
            loading={disable}
          >
            Create {itemType}
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="add-user"
        >
          <Form.Item
            name="title"
            label="Title"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter title",
                validateTrigger: onsubmit,
              },
            ]}
          >
            <Input placeholder="Enter the title" />
          </Form.Item>
          {itemType === "Course" && !project && (
            <Form.Item name="projectId" label="Project Name" required={false}>
              <Select
                showSearch
                placeholder="Select a Project"
                optionFilterProp="label"
                variant="borderless"
                popupMatchSelectWidth={true}
                allowClear
                popupClassName="sort-options"
                options={leads}
                onPopupScroll={onscroll}
                value={selectedProject}
                loading={loading}
                onSelect={(val) => setSelectedProject(val)}
                onClear={() => setSelectedProject()}
              />
            </Form.Item>
          )}
          {(selectedProject || project) &&
            questions &&
            questions?.map((item) => (
              <Form.Item
                name={item?._id}
                label={item?.questionText}
                required={false}
                rules={[
                  {
                    required: true,
                    message: "Please enter the answer",
                    validateTrigger: onsubmit,
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter the answer here"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            ))}
        </Form>
      </div>
    </Modal>
  );
};

export default CreateCourseModal;
