import { Button, Flex, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";

const ActionButtons = ({
  setIsRecording,
  startRecording,
  isRecording,
  stopRecording,
  setIsRecorderON,
  clearBlobUrl,
}) => {
  const [timer, setTimer] = useState(25);
  const [startRecordingFlag, setStartRecordingFlag] = useState(false);

  useEffect(() => {
    if (startRecordingFlag) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            stopRecording();
            setIsRecording(false);
            setStartRecordingFlag(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [startRecordingFlag]);

  return (
    <Flex gap={10}>
      <Popconfirm
        overlayStyle={{ width: 400 }}
        title="The video recording will last for 25 seconds. Do you want to continue?"
        okText="Yes"
        cancelText="No"
        onConfirm={() => {
          startRecording();
          setIsRecording(true);
          setStartRecordingFlag(true);
        }}
      >
        <Button disabled={isRecording}>Start Recording</Button>
      </Popconfirm>
      {isRecording && (
        <Button type="text">Recording... {timer}s remaining</Button>
      )}
      <Button
        onClick={() => {
          stopRecording();
          setIsRecording(false);
          clearBlobUrl();
          setIsRecorderON(false);
        }}
      >
        Back{" "}
      </Button>
    </Flex>
  );
};

export default ActionButtons;
