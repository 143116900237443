import { Card, Col, Divider, Flex, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { getAvatarForVideo } from "../../api/adminService";
import { useOutletContext } from "react-router-dom";

const StudioAvatar = () => {
  const [avatarProfiles, setAvatarProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, , setDisplayName, , userDetails] = useOutletContext();

  useEffect(() => {
    const fetchAudioProfiles = async () => {
      try {
        const res = await getAvatarForVideo();
        setAvatarProfiles(res.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching audio profiles:", error);
      }
    };
    setDisplayName(["My Avatars"]);
    setLoading(true);
    fetchAudioProfiles();
  }, []);

  if (loading) {
    return (
      <div className="avatar-loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <Flex align="flex-start" vertical flex={1}>
      <Flex vertical align="flex-start" flex={1}>
        <Divider>
          <h3>My Avatar</h3>
        </Divider>
        {avatarProfiles
          .filter((item) => item.avatarId === userDetails?.heyGenAvatarId)
          .map((profile) => (
            <Flex key={profile?.avatarId} className="avatar-col">
              <Card className={`avatar-card`}>
                <img
                  alt={profile?.name}
                  src={profile?.thumbnail}
                  className="avatar-image"
                />
                <Card.Meta title={profile?.name} className="avatar-meta" />
              </Card>
            </Flex>
          ))}
      </Flex>
      <Divider>
        <h3>Custom Avatars</h3>
      </Divider>
      <Row gutter={[16, 16]}>
        {avatarProfiles
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
          .map((profile) => (
            <Col key={profile?.avatarId} span={3} className="avatar-col">
              <Card className={`avatar-card`}>
                <img
                  alt={profile?.name}
                  src={profile?.thumbnail}
                  className="avatar-image"
                />

                <Tooltip title={profile?.name} placement="bottom">
                  <Card.Meta title={profile?.name} className="avatar-meta" />
                </Tooltip>
              </Card>
            </Col>
          ))}
      </Row>
    </Flex>
  );
};

export default StudioAvatar;
