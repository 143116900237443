import { Button, Flex, Form, Input, Popconfirm, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  generateLessonScript,
  getAudioProfiles,
  getLessonDetails,
  getUseCaseAudio,
  SubmitLessonScript,
  updateLessonDetails,
  updateScriptParts,
  updateUseCase,
} from "../../../api/adminService";
import LessonScriptUpdation from "../lessonScriptUpdation/lessonScriptUpdation";
import LessonAudio from "../lessonAudio/lessonAudio";
import LessonVideo from "../lessonVideo/lessonVideo";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../firebase/firebase";
import { query } from "firebase/firestore";
import { uploadPPT } from "../../../api/hubSpotService";
import NotificationInstance from "../../../services/notificationServices";
import { scriptStages, scriptUpdateStages } from "../../../utils/format";
import "./lessonDetails.css";
import LessonBrief from "./lessonBrief";
const LessonDetails = ({
  questions,
  step,
  courseId,
  setContentStep,
  sectionStep,
  updateStatus,
  lessons,
  setsectionStep,
  updateStep,
  lessonId,
  handleChangeSectionStep,
  setLessons,
  updateLessonStep,
  addVideoId,
}) => {
  const [form] = Form.useForm();
  const [lesson, setLesson] = useState();
  const [lessonLoading, setLessonLoading] = useState(false);

  const [lessonForm] = Form.useForm();
  const [lessonScriptForm] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [scriptUpdated, setScriptUpdated] = useState(false);
  const [selectedChunkIndex, setSelectedChunkIndex] = useState(null);
  const [selectedText, setSelectedText] = useState("");
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [final, setFinal] = useState(false);
  const [section, setSection] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [file, setFile] = useState("");
  const [loading, setloading] = useState(false);
  const [pages, setPages] = useState(0);
  let nextStepShown = false;
  const [currentStep, setcurrentStep] = useState("script");
  const [currentStatus, setcurrentStatus] = useState("script");
  const [chunks, setChunks] = useState([
    { text: "", type: "normal", order: 0 },
  ]);

  const [progress, setProgress] = useState();
  const [scriptListner, setScriptListner] = useState(false);
  const [videoId, setVideoId] = useState();
  const [audioUrlsByLesson, setAudioUrlsByLesson] = useState({});
  const [selectedScript, setSelectedScript] = useState();
  const [slidePreview, setSlidePreview] = useState();
  const [regenerating, setRegenerating] = useState([]);
  const [confirmScript, setConfirmScript] = useState(false);
  const [voiceSetting, setVoiceSetting] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(false);
  const [model, setModel] = useState();
  const [audioProfiles, setAudioProfiles] = useState([]);
  const [selectedAudioProfile, setSelectedAudioProfile] = useState(null);
  const regenerateRef = useRef(null);

  const onSubmit = (type, category, recreate) => {
    if (category === "lessonScript") {
      if (currentStep === "script") {
        lessonForm
          .validateFields()
          .then((values) => {
            setConfirmScript(true);
            let processedChunks = [];

            chunks.forEach((chunk) => {
              if (chunk.type === "normal") {
                // Split by double line breaks (\n\n) or more
                let splitTexts = chunk.text
                  .split(/\n{2,}/)
                  .filter((text) => text.trim() !== "");

                // Helper function to count sentences based on punctuation
                const countSentences = (text) => {
                  return (text.match(/[.!?](?=\s|$)/g) || []).length;
                };

                // Process text chunks
                splitTexts = splitTexts.reduce((acc, text, index, arr) => {
                  const sentenceCount = countSentences(text);

                  if (sentenceCount < 2 && index < arr.length - 1) {
                    // If fewer than 2 sentences and there's a next chunk, merge with the next
                    const nextText = arr[index + 1];
                    acc.push(text + " " + nextText); // Merge current and next text
                    arr.splice(index + 1, 1); // Remove the next one as it's now merged
                  } else {
                    // Otherwise, add the text as is
                    acc.push(text);
                  }

                  return acc;
                }, []);

                // Add the processed chunks back with updated order
                splitTexts.forEach((text) => {
                  processedChunks.push({
                    ...chunk,
                    text: text.trim(),
                    order: processedChunks.length,
                  });
                });
              } else if (
                chunk.type === "subtitle" ||
                chunk.type === "video" ||
                chunk.type === "slide"
              ) {
                processedChunks.push({
                  ...chunk,
                  text: chunk.text.replace(/\n+/g, " ").trim(),
                  order: processedChunks.length,
                });
              }
            });
            const mergedScript = processedChunks
              .map((chunk) => chunk.text)
              .join(" ");
            const answers = {
              finalScript: mergedScript,
            };
            updateLessonDetails(courseId, lesson?._id, answers)
              .then((res) => {
                updateScriptParts(courseId, lesson?._id, {
                  scriptParts: processedChunks,
                })
                  .then((res) => {
                    setConfirmScript(false);
                    setLesson(res.data);
                    updateStatus(sectionStep.id, "LESSON_SCRIPT_SUBMITTED");
                    updateLessonStep("AUDIO_GENERATION_INPROGRESS");
                    setcurrentStatus("LESSON_SCRIPT_SUBMITTED");
                    setAudioUrlsByLesson({});
                    setScriptUpdated(false);
                    setLesson((prev) => ({
                      ...prev,
                      finalScript: mergedScript,
                    }));
                    // setScript(mergedScript);
                    setChunks(processedChunks);
                    // next();
                    lessonForm.setFieldsValue(processedChunks);
                    setcurrentStep("audio");
                  })
                  .catch((err) => {
                    setConfirmScript(false);
                  });
              })
              .catch((err) => {
                setConfirmScript(false);
              });
          })
          .catch((err) => {
            console.log("Validation failed:", err);
          });
      } else {
        updateScriptParts(courseId, lesson?._id, {
          scriptParts: chunks,
        })
          .then((res) => {
            setContentStep((prev) => prev + 1);
          })
          .catch((err) => {});
      }
    } else if (category === "lessonForm" && !final) {
      lessonScriptForm
        .validateFields()
        .then((values) => {
          const { title, userContent } = values;
          const resetStepResults = Object.keys(lesson.stepResults).reduce(
            (acc, key) => {
              acc[key] = ""; // Set each step result's value to an empty string
              return acc;
            },
            {}
          );
          const answers = recreate
            ? {
                title: title,
                userContent: userContent,
                stepResults: resetStepResults,
                state: "created",
                finalScript: "",
              }
            : {
                title: title,
                userContent: userContent,
              };
          updateStatus(sectionStep.id, "GENERATING");
          setLessons((prevLessons) =>
            prevLessons.map((prev) =>
              prev.id === lesson?._id ? { ...prev, title: title } : prev
            )
          );
          updateUseCase(lesson?._id, answers)
            .then((res) => {
              setUpdated(false);

              if (!lesson.stepResults["RESULT"]) {
                generateScript("RESULT");
                setScriptListner(true);
                setSection("RESULT");
                setLesson((prev) => ({
                  ...prev,
                  title: title,
                  userContent: userContent,
                  state: "SCRIPT_GENERATION_IN_PROGRESS",
                }));

                updateStatus(sectionStep.id, "SCRIPT_GENERATION_IN_PROGRESS");
                setcurrentStatus("SCRIPT_GENERATION_IN_PROGRESS");
              } else {
                setLesson((prev) => ({
                  ...prev,
                  title: title,
                  stepResults: recreate ? resetStepResults : prev?.stepResults,
                  state: recreate ? "created" : prev?.state,
                  userContent: userContent,
                  finalScript: recreate ? "" : prev?.finalScript,
                }));
                if (recreate) {
                  setcurrentStatus("created");
                  nextStepShown = false;
                  setSelectedScript();
                }
                updateStatus(sectionStep.id, sectionStep?.lessonState);
              }
            })
            .catch((err) => {});
        })
        .catch((errorInfo) => {
          console.log("Validation Failed:", errorInfo); // Handle validation errors
        });
    } else if (category === "lessonForm" && final) {
      updateStatus(sectionStep.id, "GENERATING");

      SubmitLessonScript(courseId, lesson?._id)
        .then((res) => {
          updateStatus(sectionStep.id, "LESSON_SCRIPT_COMPLETED");
          setChunks((prev) => [{ ...prev[0], text: res?.data?.finalScript }]);
          lessonForm.setFieldsValue([
            { text: res?.data?.finalScript, type: "normal", order: 0 },
          ]);
          setContentStep((prev) => prev + 1);
          setLesson((prev) => ({
            ...prev,
            finalScript: res?.data?.finalScript,
          }));
        })
        .catch((err) => {});
    }
    // }
  };

  const uploadFile = (formData) => {
    if (!lesson?.title) {
      NotificationInstance.info({ message: "Please enter a title" });
    } else {
      uploadPPT("Course", lesson?.title, formData, videoId, lesson?._id)
        .then((res) => {
          setloading(false);
          setVideoId(res?.data?.videoId);
          setSlidePreview(res?.data?.slidePreview);
          setPages(res?.data?.totalSlides);
          setIsModalOpen(false);
          NotificationInstance.success({
            message: "File uploaded successfully",
          });
        })
        .catch((err) => {
          setloading(false);
          NotificationInstance.error({ message: "File upload failed" });
        });
    }
  };

  const saveAnnotation = (type) => {
    setScriptUpdated(true);
    if (type === "slide" && !pages) {
      NotificationInstance.info({ message: "Please upload a Presentation" });
    } else {
      if (selectedText && selectedChunkIndex !== null) {
        const chunk = chunks[selectedChunkIndex];
        const newChunks = [];
        const startIndex = chunk.text.indexOf(selectedText);
        const endIndex = startIndex + selectedText.length;

        if (startIndex > 0) {
          const preText = chunk.text.substring(0, startIndex).trim();
          if (preText) {
            newChunks.push({
              text: preText,
              type: "normal",
              order: newChunks.length,
            });
          }
        }

        newChunks.push({
          text: selectedText,
          type: type,
          order: newChunks.length,
          subtitlesText: type === "subtitle" ? currentSubtitle : null,
          promptText: type === "video" ? currentPrompt : null,
          slideNumber: type === "slide" ? 1 : null,
        });

        if (endIndex < chunk.text.length) {
          const postText = chunk.text.substring(endIndex).trim();
          if (postText) {
            newChunks.push({
              text: postText,
              type: "normal",
              order: newChunks.length,
            });
          }
        }

        const updatedChunks = [
          ...chunks.slice(0, selectedChunkIndex),
          ...newChunks,
          ...chunks.slice(selectedChunkIndex + 1),
        ];

        // Update order for all chunks
        const orderedChunks = updatedChunks.map((c, index) => ({
          ...c,
          order: index,
        }));

        setChunks(orderedChunks);

        lessonForm.setFieldsValue(orderedChunks);
        setSelectedText("");
        setSelectedChunkIndex(null);

        if (type === "subtitle") {
          setCurrentSubtitle("");
        } else {
          setCurrentPrompt("");
        }
      }
    }
  };
  const findNextStep = (previousStep) => {
    const currentIndex = questions.findIndex(
      (step) => step.lessonStep === previousStep
    );
    if (currentIndex !== -1 && currentIndex < questions.length - 1) {
      return questions[currentIndex + 1];
    }
    return null;
  };

  const onUpdate = (step) => {
    const answers = {
      stepResults: lesson.stepResults,
    };

    updateLessonDetails(courseId, lesson?._id, answers)
      .then((res) => {
        const section = findNextStep(step);
        const sectionStep = section?.lessonStep ?? false;
        const hasResult = lesson?.stepResults[sectionStep];
        console.log(sectionStep, hasResult);

        if (sectionStep && !hasResult) {
          setSelectedScript({
            step: section.lessonStep,
            question: section.message,
            script: lesson?.stepResults[section.lessonStep],
            section: section.lessonStep,
            status: "GENERATING",
          });
          setSection(sectionStep);
          generateScript(sectionStep);
        } else {
          setSelectedScript();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateScript = (step, regenerating) => {
    generateLessonScript(courseId, lesson?._id, {
      section: step,
    })
      .then((res) => {
        if (regenerating) {
          regenerateRef.current = step;
          setRegenerating((prev) => [...prev, step]);
          setcurrentStatus("SCRIPT_GENERATION_IN_PROGRESS");
        }
        // setScriptListner(true);
      })
      .catch((err) => {});
  };

  const updateLesson = (data) => {
    lessonScriptForm.setFieldsValue({ ...lesson, userContent: data });
    NotificationInstance.success({
      message: "Successfully uploaded lesson content",
    });
  };

  const handleBack = (type) => {
    if (type === "lessonScript") {
      if (currentStep === "script") {
        setContentStep((prev) => prev - 1);
      } else {
        setcurrentStep("script");
      }
    }
  };

  const handleDiscard = () => {
    if (lesson?.scriptParts?.length > 0) {
      setChunks(lesson?.scriptParts);
      lessonForm.setFieldsValue(lesson?.scriptParts);
    } else {
      setChunks([{ text: lesson?.finalScript, type: "normal", order: 0 }]);
      lessonForm.setFieldsValue([
        { text: lesson?.finalScript, type: "normal", order: 0 },
      ]);
    }
    setScriptUpdated(false);
  };

  const updateLessonSteps = (data) => {
    if (data) {
      const stepsWithValues = Object.entries(data)
        .filter(([key, value]) => value !== "")
        .map(([key, value]) => key);
      if (regenerateRef.current) {
        const current = questions?.find(
          (step) => step.lessonStep === regenerateRef.current
        );
        if (current) {
          setSelectedScript({
            step: current.lessonStep,
            question: current.message,
            script: data[current.lessonStep],
            section: current.lessonStep,
            status: "COMPLETED",
          });
        }
        regenerateRef.current = null;
      } else {
        if (stepsWithValues.length > 0) {
          const matchedStep = questions?.find(
            (step) =>
              step.lessonStep === stepsWithValues[stepsWithValues.length - 1]
          );

          if (stepsWithValues.length + 2 === questions.length) {
            setFinal(true);
            setSelectedScript({
              step: matchedStep.lessonStep,
              question: matchedStep.message,
              script: data[matchedStep.lessonStep],
              section: matchedStep.lessonStep,
              status: "COMPLETED",
            });
          } else {
            if (matchedStep) {
              setSelectedScript({
                step: matchedStep.lessonStep,
                question: matchedStep.message,
                script: data[matchedStep.lessonStep],
                section: matchedStep.lessonStep,
                status: "COMPLETED",
              });
            }
          }
        } else {
          // setLastFilledStep(null); // Reset if no steps have values
        }
      }
    }
  };
  //firebase

  useEffect(() => {
    let unsubscribe;

    if (videoId) {
      const channelRef = query(
        ref(dataBase, `leads/videos/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message?.status === "completed") {
          setProgress(message);
          setcurrentStatus("VIDEO_GENERATION_COMPLETED");
          updateStatus(lesson?._id, "VIDEO_GENERATION_COMPLETED");
          setLesson((prev) => ({
            ...prev,
            state: "VIDEO_GENERATION_COMPLETED",
          }));
        } else {
          setProgress(message);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId]);

  useEffect(() => {
    let unsubscribe;
    if (lesson?._id && currentStatus === "SCRIPT_GENERATION_IN_PROGRESS") {
      const channelRef = query(
        ref(dataBase, `lesson/${lesson?._id}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (
          message?.status === "LESSON_GENERATION_SUCCESS" ||
          message?.status === "LESSON_SCRIPT_COMPLETED"
        ) {
          setTimeout(() => {
            getLessonDetails(courseId, lesson?._id)
              .then((res) => {
                setScriptListner(false);
                setLesson(res?.data);
                updateLessonSteps(res?.data?.stepResults);
                setRegenerating([]);
              })
              .catch((err) => {});
          }, 5000);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [lesson?._id, currentStatus]);

  useEffect(() => {
    let unsubscribe;
    console.log(currentStatus, videoId);

    if (
      videoId &&
      (currentStatus === "AUDIO_GENERATION_INPROGRESS" ||
        currentStatus === "AUDIO_REGENERATION_INPROGRESS")
    ) {
      console.log("tests");

      const channelRef = query(
        ref(dataBase, `audio/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        console.log(message);

        if (message?.status === "AUDIO_GENERATED") {
          setTimeout(() => {
            getUseCaseAudio(lesson?._id)
              .then((res) => {
                setcurrentStatus("AUDIO_GENERATION_COMPLETED");
                setLesson((prev) => ({
                  ...prev,
                  state: "AUDIO_GENERATION_COMPLETED",
                }));
                const audioUrls = res?.data?.map((result) => result.url);
                setAudioUrlsByLesson(audioUrls);
                const audioData = res?.data?.map((result) => ({
                  url: result.url,
                  _id: result._id,
                  voiceSettings: result.voiceSettings,
                }));
                const updatedScriptParts = chunks.map((part, index) => ({
                  ...part,
                  url: audioData[index]?.url,
                  _id: audioData[index]?._id,
                  voiceSettings: audioData[index]?.voiceSettings,
                }));
                setVoiceSetting((prev) => ({
                  settings: prev?.settings,
                  voiceId: res?.data[0]?.voiceId,
                }));

                setChunks(updatedScriptParts);
              })
              .catch((err) => {});
          }, 5000);
        } else {
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId, lesson?._id, courseId, currentStatus]);

  //content updation

  useEffect(() => {
    if (lesson?.videoData) {
      setVideoId(lesson?.videoData?._id);
    }

    if (lesson?.audioData && lesson?.audioData?.length > 0) {
      const parts = JSON.parse(JSON.stringify(lesson?.audioData));
      const updatedParts = parts.map((part) => ({
        ...part,
        type: part?.segmentType,
      }));
      setChunks(updatedParts);
    } else if (lesson?.scriptParts && lesson?.scriptParts?.length > 0) {
      const parts = JSON.parse(JSON.stringify(lesson?.scriptParts));
      setChunks(parts);
    } else if (lesson?.finalScript) {
      setChunks((prev) => [{ ...prev[0], text: lesson?.finalScript }]);
      lessonForm.setFieldsValue([
        { text: lesson?.finalScript, type: "normal", order: 0 },
      ]);
    }
    if (lesson?.audioData?.length > 0) {
      const audioUrls = lesson?.audioData?.map((result) => result.url);
      setAudioUrlsByLesson(audioUrls);
    }
  }, [lesson?.videoData, lesson?.audioData]);

  useEffect(() => {
    if (courseId && lessonId && questions) {
      setLessonLoading(true);
      getLessonDetails(courseId, lessonId)
        .then((res) => {
          setLessonLoading(false);
          setLesson(res?.data);
          updateLessonSteps(res?.data?.stepResults);
          setModel(res?.data?.model);
          setVoiceSetting({
            settings: res?.info?.voiceSettings,
            voiceId: res?.info?.voiceId,
          });
          setcurrentStatus(res?.data?.state);
          if (res?.data?.slidePreview?.length > 0) {
            setSlidePreview(res?.data?.slidePreview[0]);
            setFile(res?.data?.presentationBlobName);
            setPages(res?.data?.totalSlides);
          }
        })
        .catch((err) => {
          setLessonLoading(false);
        });
    }
  }, [courseId, lessonId, questions]);

  useEffect(() => {
    const fetchAudioProfiles = async () => {
      try {
        const res = await getAudioProfiles();
        setAudioProfiles(res.data);
      } catch (error) {
        console.error("Error fetching audio profiles:", error);
      }
    };
    fetchAudioProfiles();
  }, []);

  const renderContent = (type) => {
    if (type === "lessonForm") {
      return lesson ? (
        <LessonBrief
          currentStatus={currentStatus}
          lesson={lesson}
          lessonScriptForm={lessonScriptForm}
          questions={questions}
          sectionStep={sectionStep}
          setSelectedScript={setSelectedScript}
          setUpdated={setUpdated}
          setFinal={setFinal}
          courseId={courseId}
          updateLesson={updateLesson}
          regenerating={regenerating}
          section={section}
          step={step}
          setContentStep={setContentStep}
          updated={updated}
          onSubmit={onSubmit}
          selectedScript={selectedScript}
          setLesson={setLesson}
          onUpdate={onUpdate}
          setSection={setSection}
          generateScript={generateScript}
        />
      ) : (
        <div></div>
      );
    } else if (type === "lessonScript") {
      return (
        <LessonScriptUpdation
          script={lesson?.finalScript}
          chunks={chunks}
          setChunks={setChunks}
          currentPrompt={currentPrompt}
          currentSubtitle={currentSubtitle}
          setCurrentPrompt={setCurrentPrompt}
          setCurrentSubtitle={setCurrentSubtitle}
          setSelectedText={setSelectedText}
          setSelectedChunkIndex={setSelectedChunkIndex}
          lessonForm={lessonForm}
          pages={pages}
          currentStatus={currentStatus}
          setScriptUpdated={setScriptUpdated}
          setAudioUrlsByLesson={setAudioUrlsByLesson}
          slidePreview={slidePreview}
          lesson={lesson}
          lessonId={lessonId}
          currentStep={currentStep}
          setcurrentStep={setcurrentStep}
          setContentStep={setContentStep}
          scriptUpdated={scriptUpdated}
          scriptUpdateStages={scriptUpdateStages}
          onSubmit={onSubmit}
          setLesson={setLesson}
          setSlidePreview={setSlidePreview}
          setPages={setPages}
          file={file}
          setFile={setFile}
          setcurrentStatus={setcurrentStatus}
          model={model}
          setModel={setModel}
          setSelectedAudioProfile={setSelectedAudioProfile}
          audioProfiles={audioProfiles}
        />
      );
    } else if (type === "lessonAudio") {
      return (
        <LessonAudio
          chunks={chunks}
          setChunks={setChunks}
          courseId={courseId}
          lessonId={lesson?._id}
          setVideoId={setVideoId}
          videoId={videoId}
          audioUrlsByLesson={audioUrlsByLesson}
          setAudioUrlsByLesson={setAudioUrlsByLesson}
          setcurrentStatus={setcurrentStatus}
          currentStatus={currentStatus}
          addVideoId={addVideoId}
          lesson={lesson}
          setContentStep={setContentStep}
          voiceSetting={voiceSetting}
          setVoiceSetting={setVoiceSetting}
          setSelectedAvatar={setSelectedAvatar}
          model={model}
          selectedAudioProfile={selectedAudioProfile}
          setSelectedAudioProfile={setSelectedAudioProfile}
          audioProfiles={audioProfiles}
        />
      );
    } else if (type === "lessonVideo") {
      return (
        <LessonVideo
          videoId={videoId}
          status={progress}
          lesson={lesson}
          lessons={lessons}
          setLesson={setLesson}
          setsectionStep={setsectionStep}
          updateStep={updateStep}
          setcurrentStatus={setcurrentStatus}
          handleChangeSectionStep={handleChangeSectionStep}
          lessonId={lessonId}
          selectedAvatar={selectedAvatar}
        />
      );
    }
  };

  return lessonLoading ? (
    <div style={{ padding: 10, height: "100%" }}>
      <Flex vertical className="course-detail-container">
        <Flex flex={1} justify="center" align="center" vertical>
          <h3>Loading Lesson Details...</h3>
          <p>Please wait while we fetching the lesson details.</p>
          <div
            className="video-loader"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <div className="loader"></div>
          </div>
        </Flex>
      </Flex>
    </div>
  ) : (
    <>{renderContent(step.type)}</>
  );
};

export default LessonDetails;
